import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTablesResponse } from 'src/app/classes/dataTablesResponse';
import { endPoint, getToken } from 'src/app/classes/endpoints';
import { Airport } from 'src/app/models/airport';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AirportService {

  
  constructor(private http: HttpClient) { }

  
  public getAirporrt(pages, size, search): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get<DataTablesResponse>(
      `${endPoint.getAirport}` + pages + size + search,
      { headers: headerss }
    );
  }
  
  public postAirport(airport: Airport) {
    return this.http.post(`${endPoint.postAirport}`, airport, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }

  
  public getbyIdAirport(id): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get<DataTablesResponse>(
      `${endPoint.getbyAirport}` + id,
      { headers: headerss }
    );
  }
  
  public deleteAirport(id) {
    return this.http.delete(`${endPoint.dropAirport}`+id, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }

  public putAirport(airport, id) {
    return this.http.put(`${endPoint.putbyAirport}`+id, airport, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
}

import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Helper } from "src/app/helper/helper";
import { CategoryService } from "src/app/service/category/category.service";
import { ProductService } from "src/app/service/product/product.service";

@Component({
  selector: "app-create-product",
  templateUrl: "./create-product.component.html",
  styleUrls: ["./create-product.component.css"],
})
export class CreateProductComponent implements OnInit {
  showLoading = false;
  myOptions: any;

  myForm = new FormGroup({
    categoryId: new FormControl("", [Validators.required]),
    code: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });
  constructor(
    private productService: ProductService,
    private categoryService: CategoryService,
    public helper: Helper,
    private router: Router
  ) {}

  get f() {
    return this.myForm.controls;
  }

  ngOnInit(): void {}

  submit(){
    if (this.myForm.invalid) {
      return;
    }
    this.showLoading = true;
    this.productService.postProduct(this.myForm.value).subscribe(
      (res) => {
        this.helper.alertStatus(200, "product has been save");
        this.showLoading = false;
        this.router.navigateByUrl("/manage/product/index");
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }

  
  onSearchCategory(searchValue) {
    this.categoryService
      .showCategory(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name,
            };
             arr.push(data);
          });
          this.myOptions=arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
}

import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Helper } from "src/app/helper/helper";
import { BranchService } from "src/app/service/branch/branch.service";
import { GroupCompanyService } from "src/app/service/group-company/group-company.service";

@Component({
  selector: "app-create-company",
  templateUrl: "./create-company.component.html",
  styleUrls: ["./create-company.component.css"],
})
export class CreateCompanyComponent implements OnInit {
  showLoading = false;
  branchSelect: any;
  groupSelect: any;

  myForm = new FormGroup({
    branchId: new FormControl("", [Validators.required]),
    code: new FormControl("", [Validators.required]),
    companyGroupId: new FormControl(" ", [Validators.required]),
    detailId: new FormControl("", [Validators.required]),
    extBranchId: new FormControl("", [Validators.required]),
    extCustId: new FormControl("", [Validators.required]),
    financeId: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
    salesById: new FormControl("", [Validators.required]),
  });
  constructor(
    public helper: Helper,
    private router: Router,
    private branchService: BranchService,
    private groupService: GroupCompanyService,
  ) {}

  get f() {
    return this.myForm.controls;
  }

  ngOnInit(): void {}
  submit() {}

  onSearchBranch(searchValue) {
    this.branchService
      .getBranch(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name,
            };
            arr.push(data);
          });
          this.branchSelect = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  onSearchGroup(searchValue) {
    this.groupService
      .getGroupCompany(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name,
            };
            arr.push(data);
          });
          this.groupSelect = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTablesResponse } from 'src/app/classes/dataTablesResponse';
import { endPoint, getToken } from 'src/app/classes/endpoints';
import { Assets } from 'src/app/models/asset';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {
  constructor(private http: HttpClient) {}

  public getAsset(id, pages, size, search): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get<DataTablesResponse>(
      `${endPoint.getAssets}/category/${id}` + pages + size + search,
      { headers: headerss }
    );
  }
  
  public postAsset(asset: Assets) {
    return this.http.post(`${endPoint.postAssets}`, asset, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
  
  public deleteAssets(id) {
    return this.http.delete(`${endPoint.dropAssets}`+id, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
  
  public getbyIdAsset(id): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get<DataTablesResponse>(
      `${endPoint.getbyAssets}` + id,
      { headers: headerss }
    );
  }

  public putAsset(data, id) {
    return this.http.put(`${endPoint.putAssets}`+id, data, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
}

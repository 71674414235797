import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataTablesResponse } from "src/app/classes/dataTablesResponse";
import { City } from "src/app/models/city";
import { environment } from "src/environments/environment";
import { endPoint, getToken } from "../../classes/endpoints";

@Injectable({
  providedIn: "root",
})
export class CityserviceService {
  constructor(private http: HttpClient) {}

  public getCity(pages, size, search): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get<DataTablesResponse>(
      `${endPoint.getCity}` + pages + size + search,
      { headers: headerss }
    );
  }
  
  public getbyIdCity(id): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get<DataTablesResponse>(
      `${endPoint.getbyCity}` + id,
      { headers: headerss }
    );
  }
  
  public postCity(cityData: City) {
    return this.http.post(`${endPoint.postCity}`, cityData, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }

  public deleteCity(id) {
    return this.http.delete(`${endPoint.dropCity}`+id, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
  
  public putCity(cityData, id) {
    return this.http.put(`${endPoint.putCity}`+id, cityData, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
}

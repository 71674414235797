import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Helper } from "src/app/helper/helper";
import { Depertements } from "src/app/models/depertement";
import { DepartmentService } from "src/app/service/department/department.service";

@Component({
  selector: "app-index-departments",
  templateUrl: "./index-departments.component.html",
  styleUrls: ["./index-departments.component.css"],
})
export class IndexDepartmentsComponent implements OnInit {
  showLoading = false;
  dtOptions: DataTables.Settings = {};
  depertement: Depertements[] = [];

  constructor(
    private depertementService: DepartmentService,
    public helper: Helper,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getDepertement();
  }

  getDepertement() {
    this.showLoading = true;
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const pages =
          "" + dataTablesParameters.search.value === ""
            ? "?page=" + (dataTablesParameters.draw - 1) + ""
            : "?page=0" + "";
        const size = "&size=" + dataTablesParameters.length + "";
        const search =
          dataTablesParameters.search.value === ""
            ? ""
            : "&key=" + dataTablesParameters.search.value + "";
        this.depertementService
          .getDepertement(pages, size, search)
          .subscribe((res) => {
            that.depertement = res.content;
            this.showLoading = false;
            callback({
              recordsTotal: res.totalElements,
              recordsFiltered: res.totalPages,
              data: [],
            });
          });
      },
    };
  }

  deletePost(id){
    
    this.showLoading = true;
    this.depertementService.deleteDepertements(id).subscribe(
      (res) => {
        this.helper.alertStatus(200, "Depertement has been delete");
        this.showLoading = false;
        window.location.reload()
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-branch',
  templateUrl: './update-branch.component.html',
  styleUrls: ['./update-branch.component.css']
})
export class UpdateBranchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataTablesResponse } from "src/app/classes/dataTablesResponse";
import { endPoint, getToken } from "src/app/classes/endpoints";
import { Airline } from "src/app/models/airline";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AirlineService {
  constructor(private http: HttpClient) {}

  public getAirline(pages, size, search): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get<DataTablesResponse>(
      `${endPoint.getAirline}` + pages + size + search,
      { headers: headerss }
    );
  }
  
  public postAirline(airLine: Airline) {
    return this.http.post(`${endPoint.postAirline}`, airLine, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
  
  public deleteAirline(id) {
    return this.http.delete(`${endPoint.dropAirline}`+id, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
  
  public getbyIdAirline(id): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get<DataTablesResponse>(
      `${endPoint.getbyAirline}` + id,
      { headers: headerss }
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Helper } from "src/app/helper/helper";
import { CurrencyService } from "src/app/service/currency/currency.service";
import { GroupCompanyService } from "src/app/service/group-company/group-company.service";

@Component({
  selector: "app-create-company-group",
  templateUrl: "./create-company-group.component.html",
  styleUrls: ["./create-company-group.component.css"],
})
export class CreateCompanyGroupComponent implements OnInit {
  showLoading = false;
  currencySelect: any;
  groupSelect: any;
  checkActiveFlagAddon: any;
  checkactiveFlagCr: any;

  myForm = new FormGroup({
    code: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
    activeFlagAddon: new FormControl(" ", [Validators.required]),
    activeFlagCr: new FormControl("", [Validators.required]),
    activePpn: new FormControl("", [Validators.required]),
    addOnAmount: new FormControl("", [Validators.required]),
    addOnFrom: new FormControl("", [Validators.required]),
    addOnTo: new FormControl("", [Validators.required]),
    creditLimit: new FormControl("", [Validators.required]),
    currencyId: new FormControl("", [Validators.required]),
    currentTransaction: new FormControl("", [Validators.required]),
    showComm: new FormControl("", [Validators.required]),
    showFee: new FormControl("", [Validators.required]),
    showMarkup: new FormControl("", [Validators.required]),
    showPpn: new FormControl("", [Validators.required]),
  });
  constructor(
    public helper: Helper,
    private router: Router,
    private currancyService: CurrencyService,
    private groupService: GroupCompanyService,
  ) {
    this.checkActiveFlagAddon = 1;
    this.checkactiveFlagCr = 0;
  }

  get f() {
    return this.myForm.controls;
  }

  ngOnInit(): void {}
  submit() {}

  onSearchCurrancy(searchValue) {
    this.currancyService
      .getCurrency(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name,
            };
            arr.push(data);
          });
          this.currencySelect = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  onSearchGroup(searchValue) {
    this.groupService
      .getGroupCompany(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name,
            };
            arr.push(data);
          });
          this.groupSelect = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
}

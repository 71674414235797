import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataTablesResponse } from "src/app/classes/dataTablesResponse";
import { Cabin } from "src/app/models/cabin";
import { environment } from "src/environments/environment";
import { endPoint, getToken } from "../../classes/endpoints";

@Injectable({
  providedIn: 'root'
})
export class CabinService {

  constructor(private http: HttpClient) {}

  public getCabin(pages, size, search): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get<DataTablesResponse>(
      `${endPoint.getCabin}` + pages + size + search,
      { headers: headerss }
    );
  }
  
  public getbyIdCabin(id): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get<DataTablesResponse>(
      `${endPoint.getbyCabin}` + id,
      { headers: headerss }
    );
  }
  
  public postCabin(cabinData: Cabin) {
    return this.http.post(`${endPoint.postCabin}`, cabinData, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }

  public deleteCabin(id) {
    return this.http.delete(`${endPoint.dropCabin}`+id, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
  
  public putCabin(cabinData, id) {
    return this.http.put(`${endPoint.putCabin}`+id, cabinData, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/helper';
import { Product } from 'src/app/models/product';
import { ProductService } from 'src/app/service/product/product.service';

@Component({
  selector: 'app-index-product',
  templateUrl: './index-product.component.html',
  styleUrls: ['./index-product.component.css']
})
export class IndexProductComponent implements OnInit {
  showLoading = false;
  dtOptions: DataTables.Settings = {};
  product: Product[] = [];

  constructor(
    private productService: ProductService,
    public helper: Helper,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getProduct();
  }

  getProduct() {
    this.showLoading = true;
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const pages =
          "" + dataTablesParameters.search.value === ""
            ? "?page=" + (dataTablesParameters.draw - 1) + ""
            : "?page=0" + "";
        const size = "&size=" + dataTablesParameters.length + "";
        const search =
          dataTablesParameters.search.value === ""
            ? ""
            : "&key=" + dataTablesParameters.search.value + "";
        this.productService
          .getProduct(pages, size, search)
          .subscribe((res) => {
            that.product = res.content;
            this.showLoading = false;
            callback({
              recordsTotal: res.totalElements,
              recordsFiltered: res.totalPages,
              data: [],
            });
          });
      },
    };
  }
  deletePost(e){
    this.showLoading = true;
    this.productService.deleteProduct(e).subscribe(
      (res) => {
        this.helper.alertStatus(200, "country has been save");
        this.showLoading = false;
        window.location.reload()
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
}

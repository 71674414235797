import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Helper } from "src/app/helper/helper";
import { Currency } from "src/app/models/currency";
import { CurrencyService } from "src/app/service/currency/currency.service";

@Component({
  selector: "app-index-currency",
  templateUrl: "./index-currency.component.html",
  styleUrls: ["./index-currency.component.css"],
})
export class IndexCurrencyComponent implements OnInit {
  showLoading = false;
  dtOptions: DataTables.Settings = {};
  currency: Currency[] = [];

  constructor(
    private currencyService: CurrencyService,
    public helper: Helper,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCurrency();
  }

  getCurrency() {
    this.showLoading = true;
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const pages =
          "" + dataTablesParameters.search.value === ""
            ? "?page=" + (dataTablesParameters.draw - 1) + ""
            : "?page=0" + "";
        const size = "&size=" + dataTablesParameters.length + "";
        const search =
          dataTablesParameters.search.value === ""
            ? ""
            : "&key=" + dataTablesParameters.search.value + "";
        this.currencyService
          .getCurrency(pages, size, search)
          .subscribe((res) => {
            that.currency = res.content;
            this.showLoading = false;
            callback({
              recordsTotal: res.totalElements,
              recordsFiltered: res.totalPages,
              data: [],
            });
          });
      },
    };
  }
  
  deletePost(id){
    this.showLoading = true;
    this.currencyService.deleteCurrency(id).subscribe(
      (res) => {
        this.helper.alertStatus(200, "Currency has been save");
        this.showLoading = false;
        window.location.reload()
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
}

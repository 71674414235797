import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Helper } from "src/app/helper/helper";
import { BranchService } from "src/app/service/branch/branch.service";
import { CityserviceService } from "src/app/service/city/cityservice.service";
import { CountryService } from "src/app/service/country/country.service";
import { CurrencyService } from "src/app/service/currency/currency.service";

@Component({
  selector: "app-create-branch",
  templateUrl: "./create-branch.component.html",
  styleUrls: ["./create-branch.component.css"],
})
export class CreateBranchComponent implements OnInit {
  showLoading = false;
  selectedItems: any;
  citys: any;
  country: any;
  bodyPost: any;
  selectedCityId: number;

  myOptions: any;
  myOptionsCountry: any;
  myOptionsCurrency: any;
  myForm = new FormGroup({
    address: new FormControl("", [Validators.required]),
    type: new FormControl("", [Validators.required]),
    cityId: new FormControl(" ", [Validators.required]),
    countryId: new FormControl("", [Validators.required]),
    currencyId: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required]),
    fax: new FormControl(""),
    npwpAddress: new FormControl(""),
    npwpName: new FormControl(""),
    npwpNo: new FormControl(""),
    phone: new FormControl(""),
    phone1: new FormControl(""),
    postcode: new FormControl(""),
    branchStatus: new FormControl("", [Validators.required]),
    code: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
    no: new FormControl("", [Validators.required]),
  });

  constructor(
    private branchService: BranchService,
    private cityService: CityserviceService,
    private currencyService: CurrencyService,
    public helper: Helper,
    private router: Router,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {}

  get f() {
    return this.myForm.controls;
  }
  submit() {
    if (this.myForm.invalid) {
      return;
    }
    this.showLoading = true;
    const data = this.myForm.value;
    let body ={
      branchDetail: {
        branchAddress: [
          {
            address: data.address,
            type: data.type
          }
        ],
        cityId: data.cityId,
        countryId: data.countryId,
        currencyId: data.currencyId,
        email: data.email,
        fax: data.fax,
        npwpAddress: data.npwpAddress,
        npwpName: data.npwpName,
        npwpNo: data.npwpNo,
        phone: data.phone,
        phone1: data.phone1,
        postcode: data.postcode
      },
      branchStatus: parseInt(data.branchStatus),
      code: data.code,
      name: data.name,
      no: data.no
    }
    this.bodyPost=body;
    console.log(this.bodyPost)
    this.branchService.postBranch(this.bodyPost).subscribe(
      (res) => {
        this.helper.alertStatus(200, "branch has been save");
        this.showLoading = false;
        this.router.navigateByUrl("/manage/branch/index");
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }

  onChangeCity(event: string) {
    this.citys = event;
  }
  onSearchCurrency(searchValue) {
    this.currencyService
      .getCurrency(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name + "-" + item.code,
            };
            arr.push(data);
          });
          this.myOptionsCurrency = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  onSearchCountry(searchValue) {
    this.countryService
      .getCountry(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name + "-" + item.code,
            };
            arr.push(data);
          });
          this.myOptionsCountry = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  onChangeCountry(event: string) {
    this.country = event;
    this.countryService
      .getCityCountry(this.country.id)
      .subscribe(
        (res) => {
          const arr = [];
          res.detailCity.map((item) => {
            let data = {
              id: item.id,
              name: item.name + "-" + item.code,
            };
            arr.push(data);
          });
          this.myOptions = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Helper } from "src/app/helper/helper";
import { CompanyGroup } from "src/app/models/companyGroup";
import { GroupCompanyService } from "src/app/service/group-company/group-company.service";

@Component({
  selector: "app-index-company-group",
  templateUrl: "./index-company-group.component.html",
  styleUrls: ["./index-company-group.component.css"],
})
export class IndexCompanyGroupComponent implements OnInit {
  showLoading = false;
  dtOptions: DataTables.Settings = {};
  companyGroup: CompanyGroup[] = [];

  constructor(
    private companyGroupService: GroupCompanyService,
    public helper: Helper,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCompanyGroup();
  }

  getCompanyGroup() {
    this.showLoading = true;
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const pages =
          "" + dataTablesParameters.search.value === ""
            ? "?page=" + (dataTablesParameters.draw - 1) + ""
            : "?page=0" + "";
        const size = "&size=" + dataTablesParameters.length + "";
        const search =
          dataTablesParameters.search.value === ""
            ? ""
            : "&key=" + dataTablesParameters.search.value + "";
        this.companyGroupService.getGroupCompany(pages, size, search).subscribe((res) => {
          that.companyGroup = res.content;
          this.showLoading = false;
          callback({
            recordsTotal: res.totalElements,
            recordsFiltered: res.totalPages,
            data: [],
          });
        });
      },
    };
  }

  deletePost(e) {}
}

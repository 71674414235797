import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/helper';
import { File } from 'src/app/models/file';
import { AirlineService } from 'src/app/service/airline/airline.service';
import { FileService } from 'src/app/service/file/file.service';

@Component({
  selector: 'app-create-airline',
  templateUrl: './create-airline.component.html',
  styleUrls: ['./create-airline.component.css']
})
export class CreateAirlineComponent implements OnInit {

  showLoading = false;
  data: any = {};
  myForm = new FormGroup({
    code: new FormControl("", [Validators.required]),
    domesticComm: new FormControl("0", [Validators.required]),
    domesticFlag: new FormControl(" ", [Validators.required]),
    internationalComm: new FormControl("0", [Validators.required]),
    internationalFlag: new FormControl("", [Validators.required]),
    logoId: new FormControl(''),
    name: new FormControl("", [Validators.required]),
    no: new FormControl("", [Validators.required]),
    status: new FormControl("", [Validators.required]),
  });

  
  constructor(
    private airlineService: AirlineService, 
    private fileService: FileService, 
    public helper: Helper, 
    private router: Router) {}

  ngOnInit(): void {
  }
  uploadImage(e){
    this.fileService.postFiles(e.target.files[0]).subscribe(
      (res) => {
        this.data=res;
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
      }
    );
  }
  get f() {
    return this.myForm.controls;
  }
  submit() {
    if (this.myForm.invalid) {
      return;
    }
    this.showLoading = true;
    this.airlineService.postAirline(this.myForm.value).subscribe(
      (res) => {
        this.helper.alertStatus(200, "city has been save");
        this.showLoading = false;
        this.router.navigateByUrl("/manage/airline/index");
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
}

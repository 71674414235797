import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Helper } from "src/app/helper/helper";
import { Company } from "src/app/models/company";
import { CompanyService } from "src/app/service/company/company.service";

@Component({
  selector: "app-index-company",
  templateUrl: "./index-company.component.html",
  styleUrls: ["./index-company.component.css"],
})
export class IndexCompanyComponent implements OnInit {
  showLoading = false;
  dtOptions: DataTables.Settings = {};
  company: Company[] = [];

  constructor(
    private companyService: CompanyService,
    public helper: Helper,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCompany()
  }

  
  getCompany(){
    this.showLoading = true;
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const pages =
          "" + dataTablesParameters.search.value === ""
            ? "?page=" + (dataTablesParameters.draw - 1) + ""
            : "?page=0" + "";
        const size = "&size=" + dataTablesParameters.length + "";
        const search =
          dataTablesParameters.search.value === ""
            ? ""
            : "&key=" + dataTablesParameters.search.value + "";
        this.companyService.getCompany(pages, size, search).subscribe((res) => {
          that.company = res.content;
          this.showLoading = false;
          callback({
            recordsTotal: res.totalElements,
            recordsFiltered: res.totalPages,
            data: [],
          });
        });
      },
    };
  }

  deletePost(e){

  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/helper';
import { PriceTemplateService } from 'src/app/service/price-template/price-template.service';

@Component({
  selector: 'app-index-price-templates',
  templateUrl: './index-price-templates.component.html',
  styleUrls: ['./index-price-templates.component.css']
})
export class IndexPriceTemplatesComponent implements OnInit {
  showLoading = false;
  dtOptions: DataTables.Settings = {};
  priceTemplate: [];

  
  constructor(
    private priceTemplateService: PriceTemplateService,
    public helper: Helper,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getpriceTemplate()
  }

  getpriceTemplate() {
    this.showLoading = true;
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const pages =
          "" + dataTablesParameters.search.value === ""
            ? "?page=" + (dataTablesParameters.draw - 1) + ""
            : "?page=0" + "";
        const size = "&size=" + dataTablesParameters.length + "";
        const search =
          dataTablesParameters.search.value === ""
            ? ""
            : "&key=" + dataTablesParameters.search.value + "";
        this.priceTemplateService
          .getPriceTemplate(pages, size, search)
          .subscribe((res) => {
            that.priceTemplate = res.content;
            this.showLoading = false;
            callback({
              recordsTotal: res.totalElements,
              recordsFiltered: res.totalPages,
              data: [],
            });
          });
      },
    };
  }
  deletePost(e){

  }
}

import { environment } from "src/environments/environment";

export const getToken = {
  token:localStorage.getItem("ACCESS_TOKEN")
}

export const endPoint = {
  login:`${environment.service_digi}/oauth/token`,
  getCity:`${environment.service_digi}/api/managed-cities`,
  postCity:`${environment.service_digi}/api/managed-cities`,
  dropCity:`${environment.service_digi}/api/managed-cities/`,
  getbyCity:`${environment.service_digi}/api/managed-cities/`,
  putCity:`${environment.service_digi}/api/managed-cities/`,

  getCountry:`${environment.service_digi}/api/managed-countries`,
  postCountry:`${environment.service_digi}/api/managed-countries`,
  dropCountry:`${environment.service_digi}/api/managed-countries/`,
  getbyCountry:`${environment.service_digi}/api/managed-countries/`,
  putbyCountry:`${environment.service_digi}/api/managed-countries/`,
  getCitybyCountry:`${environment.service_digi}/api/managed-cities/country/`,

  getAirline:`${environment.service_digi}/api/managed-airlines`,
  postAirline:`${environment.service_digi}/api/managed-airlines`,
  dropAirline:`${environment.service_digi}/api/managed-airlines/`,
  getbyAirline:`${environment.service_digi}/api/managed-airlines/`,
  putbyAirline:`${environment.service_digi}/api/managed-airlines/`,
  file:`${environment.service_digi}/images`,

  getAirport:`${environment.service_digi}/api/managed-airports`,
  postAirport:`${environment.service_digi}/api/managed-airports`,
  dropAirport:`${environment.service_digi}/api/managed-airports/`,
  getbyAirport:`${environment.service_digi}/api/managed-airports/`,
  putbyAirport:`${environment.service_digi}/api/managed-airports/`,
  company:`${environment.service_digi}/api/managed-companies`,

  getCabin:`${environment.service_digi}/api/managed-cabins`,
  postCabin:`${environment.service_digi}/api/managed-cabins`,
  dropCabin:`${environment.service_digi}/api/managed-cabins/`,
  getbyCabin:`${environment.service_digi}/api/managed-cabins/`,
  putCabin:`${environment.service_digi}/api/managed-cabins/`,
  
  getBranch:`${environment.service_digi}/api/managed-branch`,
  postBranch:`${environment.service_digi}/api/managed-branch`,
  dropBranch:`${environment.service_digi}/api/managed-branch/`,
  getbyBranch:`${environment.service_digi}/api/managed-branch/`,
  putBranch:`${environment.service_digi}/api/managed-branch/`,

  getcompanyGroup:`${environment.service_digi}/api/managed-company-group`,
  postcompanyGroup:`${environment.service_digi}/api/managed-company-group`,
  dropcompanyGroup:`${environment.service_digi}/api/managed-company-group/`,
  getbycompanyGroup:`${environment.service_digi}/api/managed-company-group/`,
  putcompanyGroup:`${environment.service_digi}/api/managed-company-group/`,

  
  getCategory:`${environment.service_digi}/api/managed-categories`,
  postCategory:`${environment.service_digi}/api/managed-categories`,
  dropCategory:`${environment.service_digi}/api/managed-categories/`,
  getbyCategory:`${environment.service_digi}/api/managed-categories/`,
  putCategory:`${environment.service_digi}/api/managed-categories/`,
  
  getCurrencies:`${environment.service_digi}/api/managed-currencies`,
  postCurrencies:`${environment.service_digi}/api/managed-currencies`,
  dropCurrencies:`${environment.service_digi}/api/managed-currencies/`,
  getbyCurrencies:`${environment.service_digi}/api/managed-currencies/`,
  putCurrencies:`${environment.service_digi}/api/managed-currencies/`,
  
  getPriceTemplate:`${environment.service_digi}/api/managed-price-template`,
  postPriceTemplate:`${environment.service_digi}/api/managed-price-template`,
  dropPriceTemplate:`${environment.service_digi}/api/managed-price-template/`,
  getbyPriceTemplate:`${environment.service_digi}/api/managed-price-template/`,
  putPriceTemplate:`${environment.service_digi}/api/managed-price-template/`,
  
  getProduct:`${environment.service_digi}/api/managed-products`,
  postProduct:`${environment.service_digi}/api/managed-products`,
  dropProduct:`${environment.service_digi}/api/managed-products/`,
  getbyProduct:`${environment.service_digi}/api/managed-products/`,
  putProduct:`${environment.service_digi}/api/managed-products/`,
  
  getDepartments:`${environment.service_digi}/api/managed-departments`,
  postDepartments:`${environment.service_digi}/api/managed-departments`,
  dropDepartments:`${environment.service_digi}/api/managed-departments/`,
  getbyDepartments:`${environment.service_digi}/api/managed-departments/`,
  putDepartments:`${environment.service_digi}/api/managed-departments/`,
  
  getAssets:`${environment.service_digi}/api/managed-assets`,
  postAssets:`${environment.service_digi}/api/managed-assets`,
  dropAssets:`${environment.service_digi}/api/managed-assets/`,
  getbyAssets:`${environment.service_digi}/api/managed-assets/`,
  putAssets:`${environment.service_digi}/api/managed-assets/`,

  totalSelling:`${environment.service_digi}/api/managed-statistics/total-selling?`,
  totaldomesticFlight:`${environment.service_digi}/api/managed-statistics/domestic/`,
  totalinternationalFlight:`${environment.service_digi}/api/managed-statistics/international/`,
  topCompany:`${environment.service_digi}/api/managed-statistics/top-companies?type=`,
  getfailedInvoice:`${environment.service_digi}/api/managed-statistics/failed-invoices`,
};
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from 'src/app/helper/helper';
import { DepartmentService } from 'src/app/service/department/department.service';

@Component({
  selector: 'app-update-departments',
  templateUrl: './update-departments.component.html',
  styleUrls: ['./update-departments.component.css']
})
export class UpdateDepartmentsComponent implements OnInit {
  showLoading = false;
  id:any;
  data: any = {};

  myForm = new FormGroup({
    code: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });
  constructor(
    private depertementService: DepartmentService,
    public helper: Helper,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  get f() {
    return this.myForm.controls;
  }

  ngOnInit(): void {
    this.getDepertement();
  }

  getDepertement(){
    this.id = this.route.snapshot.params['id'];
    this.depertementService.getbyIdDepertement(this.id).subscribe(
      (res) => {
        this.data = res;
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
  
  submit() {
    if (this.myForm.invalid) {
      return;
    }
    this.id = this.route.snapshot.params['id'];
    this.showLoading = true;
    this.depertementService.putDepertements(this.myForm.value, this.id).subscribe(
      (res) => {
        this.helper.alertStatus(200, "city has been save");
        this.showLoading = false;
        this.router.navigateByUrl("/manage/depertement/index");
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
}

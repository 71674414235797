import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Helper } from 'src/app/helper/helper';
import { Country } from 'src/app/models/country';
import { CountryService } from 'src/app/service/country/country.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  showLoading = false;
  dtOptions: DataTables.Settings = {};
  country: Country[] = [];

  constructor(private coutryService:CountryService,
    public helper:Helper,
    private router:Router) { }

  ngOnInit(): void {
    this.getCountry();
  }

  getCountry(){
    this.showLoading = true;
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const pages =
          "" + dataTablesParameters.search.value === ""
            ? "?page=" + (dataTablesParameters.draw - 1) + ""
            : "?page=0" + "";
        const size = "&size=" + dataTablesParameters.length + "";
        const search =
          dataTablesParameters.search.value === ""
            ? ""
            : "&key=" + dataTablesParameters.search.value + "";
        this.coutryService.getCountry(pages, size, search).subscribe((res) => {
          that.country = res.content;
          this.showLoading = false;
          callback({
            recordsTotal: res.totalElements,
            recordsFiltered: res.totalPages,
            data: [],
          });
        });
      },
    };
  }
  deletePost(id){
    this.showLoading = true;
    this.coutryService.deleteCountry(id).subscribe(
      (res) => {
        this.helper.alertStatus(200, "country has been save");
        this.showLoading = false;
        window.location.reload()
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
}

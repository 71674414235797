import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/helper';
import { PriceTemplateService } from 'src/app/service/price-template/price-template.service';

@Component({
  selector: 'app-create-price-templates',
  templateUrl: './create-price-templates.component.html',
  styleUrls: ['./create-price-templates.component.css']
})
export class CreatePriceTemplatesComponent implements OnInit {
  showLoading = false;

  myForm = new FormGroup({
    companyId: new FormControl("", [Validators.required]),
    pricingType: new FormControl("", [Validators.required]),
    supplierId: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });
  constructor(
    private priceTemplateService: PriceTemplateService,
    public helper: Helper,
    private router: Router
  ) {}

  get f() {
    return this.myForm.controls;
  }

  ngOnInit(): void {
  }
  submit(){

  }
}

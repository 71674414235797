import { Component, OnInit } from "@angular/core";
import { Cabin } from "src/app/models/cabin";
import {
  NgbModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IOption } from "ng-select";
import { Helper } from "src/app/helper/helper";
import { CabinService } from "src/app/service/cabin/cabin.service";

@Component({
  selector: "app-cabin",
  templateUrl: "./cabin.component.html",
  styleUrls: ["./cabin.component.css"],
})
export class CabinComponent implements OnInit {
  showLoading = false;
  dtOptions: DataTables.Settings = {};
  cabins: Cabin[] = [];
  closeResult = "";
  showModal: boolean;
  id: number;
  detail: any = {};
  data: any = {};
  myOptions: Array<IOption> = [];
  myOptionsEdit: Array<IOption> = [];

  myForm = new FormGroup({
    code: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });
  myFormEdit = new FormGroup({
    id: new FormControl("", [Validators.required]),
    code: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });
  constructor(
    private cabinService: CabinService,
    private modalService: NgbModal,
    public helper: Helper
  ) {}

  ngOnInit(): void {
    this.getCabin();
  }
  getCabin() {
    this.showLoading = true;
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const pages =
          "" + dataTablesParameters.search.value === ""
            ? "?page=" + (dataTablesParameters.draw - 1) + ""
            : "?page=0" + "";
        const size = "&size=" + dataTablesParameters.length + "";
        const search =
          dataTablesParameters.search.value === ""
            ? ""
            : "&key=" + dataTablesParameters.search.value + "";
        this.cabinService.getCabin(pages, size, search).subscribe((res) => {
          that.cabins = res.content;
          this.showLoading = false;
          callback({
            recordsTotal: res.totalElements,
            recordsFiltered: res.totalPages,
            data: [],
          });
        });
      },
    };
  }
  optionCountry() {
    const serach = "";
    this.cabinService.getbyIdCabin(serach).subscribe(
      (res) => {
        res.content.map((item) => {
          let data = {
            label: item.name,
            value: item.id,
          };
          this.myOptions.push(data);
        });
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
      }
    );
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  get f() {
    return this.myForm.controls;
  }

  get e() {
    return this.myFormEdit.controls;
  }
  submit() {
    if (this.myForm.invalid) {
      return;
    }
    this.showLoading = true;
    this.cabinService.postCabin(this.myForm.value).subscribe(
      (res) => {
        this.helper.alertStatus(200, "cabin has been saved");
        this.modalService.dismissAll();
        this.getCabin();
        this.showLoading = false;
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }

  deletePost(id) {
    this.showLoading = true;
    this.cabinService.deleteCabin(id).subscribe(
      (res) => {
        this.helper.alertStatus(200, "cabin has been removed");
        this.getCabin();
        this.showLoading = false;
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
  editModal(id) {
    this.showLoading = true;
    this.showModal = true;
    this.cabinService.getbyIdCabin(id).subscribe(
      (res) => {
          let body ={
            code:res.code,
            id:res.id,
            name:res.name
          }
        
          this.detail = body;
          this.showLoading = false;
      },
      (err) => {
        this.showLoading = false;}
    );
  }
  submitEdit() {
    this.showLoading = true;
    let data = {
      code: this.myFormEdit.value.code,
      name: this.myFormEdit.value.name
    }
    this.cabinService.putCabin(data, this.myFormEdit.value.id).subscribe(
      (res) => {
        this.getCabin();
        this.helper.alertStatus(200, "city has been save");
        this.showModal = false;
        this.showLoading = false;
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
  hide() {
    this.showModal = false;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Helper } from 'src/app/helper/helper';
import { Assets } from 'src/app/models/asset';
import { AssetsService } from 'src/app/service/assets/assets.service';
import { CategoryService } from 'src/app/service/category/category.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-index-interpace',
  templateUrl: './index-interpace.component.html',
  styleUrls: ['./index-interpace.component.css']
})
export class IndexInterpaceComponent implements OnInit {
  showLoading = false;
  dtOptions: DataTables.Settings = {};
  asset: Assets[] = [];
  myOptionsCategory: any;
  datatableElement: DataTableDirective;

  constructor(private assetService:AssetsService,
    private categoryService:CategoryService,
    public helper:Helper,
    private router:Router) { }
  ngOnInit(): void {
    this.getAsset(1);
  }
  getAsset(id){
    this.showLoading = true;
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const pages =
          "" + dataTablesParameters.search.value === ""
            ? "?page=" + (dataTablesParameters.draw - 1) + ""
            : "?page=0" + "";
        const size = "&size=" + dataTablesParameters.length + "";
        const search =
          dataTablesParameters.search.value === ""
            ? ""
            : "&key=" + dataTablesParameters.search.value + "";
        this.assetService.getAsset(id, pages, size, search).subscribe((res) => {
          that.asset = res.content;
          this.showLoading = false;
          callback({
            recordsTotal: res.totalElements,
            recordsFiltered: res.totalPages,
            data: [],
          });
        });
      },
    };
  }
  deletePost(id){
    this.showLoading = true;
    this.assetService.deleteAssets(id).subscribe(
      (res) => {
        this.helper.alertStatus(200, "asset has been save");
        this.showLoading = false;
        window.location.reload()
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }

  onChangeCategory(e){
    this.getAsset(e.id)
  }
  onSearchCategory(searchValue) {
    this.categoryService
      .showCategory(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name,
            };
             arr.push(data);
          });
          this.myOptionsCategory=arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/helper';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  showLoading = false;
  sallingDeally: any = {};
  sallingWeekly: any = {};
  sallingMonths: any = {};
  salling3Months: any = {};
  totalDomestikFlight: any = {};
  totalDomestikHotel: any = {};
  totalInternationalFlight: any = {};
  totalInternationalHotel: any = {};
  topCompanys: any = [];
  topCompanysFlight: any = [];
  dtOptions: DataTables.Settings = {};
  faildInvoice: any = [];
  constructor(
    private dashboardService:DashboardService,
    public helper:Helper,
    private router:Router) { }

  ngOnInit(): void {
    this.getSellingDaily();
    this.getSellingWeekly();
    this.getSellingMoth();
    this.getSelling3Moth();
    this.gettotalDomestikflight();
    this.gettotalDomestikhotel();
    this.gettotalInternationalhotel();
    this.gettotalInternationalflight();
    this.topCompanyHotel();
    this.topCompanyFlight();
    this.getFaildInvoices();
  }
  getFaildInvoices(){
    this.showLoading = true;
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const pages =
          "" + dataTablesParameters.search.value === ""
            ? "?page=" + (dataTablesParameters.draw - 1) + ""
            : "?page=0" + "";
        const size = "&size=" + dataTablesParameters.length + "";
        const search =
          dataTablesParameters.search.value === ""
            ? ""
            : "&key=" + dataTablesParameters.search.value + "";
        this.dashboardService.getFaildInvoice(pages, size, search).subscribe((res) => {
          that.faildInvoice = res.content;
          this.showLoading = false;
          callback({
            recordsTotal: res.totalElements,
            recordsFiltered: res.totalPages,
            data: [],
          });
        });
      },
    };
  }

  topCompanyFlight(){
    this.dashboardService
      .getTopCompany(2)
      .subscribe(
        (res) => {
          this.topCompanysFlight=res;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }

  topCompanyHotel(){
    this.dashboardService
      .getTopCompany(1)
      .subscribe(
        (res) => {
          this.topCompanys=res;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }

  gettotalInternationalflight(){
    this.dashboardService
      .getInternational('flight', 1)
      .subscribe(
        (res) => {
          this.totalInternationalFlight=res;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }

  gettotalInternationalhotel(){
    this.dashboardService
      .getInternational('hotel', 1)
      .subscribe(
        (res) => {
          this.totalInternationalHotel=res;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }

  gettotalDomestikhotel(){
    this.dashboardService
      .getDomestikFlight('hotel', 1)
      .subscribe(
        (res) => {
          this.totalDomestikHotel=res;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }

  gettotalDomestikflight(){
    this.dashboardService
      .getDomestikFlight('flight', 1)
      .subscribe(
        (res) => {
          this.totalDomestikFlight=res;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  getSelling3Moth(){
    this.dashboardService
      .getSelling(4)
      .subscribe(
        (res) => {
          this.salling3Months=res;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  getSellingMoth(){
    this.dashboardService
      .getSelling(3)
      .subscribe(
        (res) => {
          this.sallingMonths=res;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  getSellingWeekly(){
    this.dashboardService
      .getSelling(2)
      .subscribe(
        (res) => {
          this.sallingWeekly=res;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  getSellingDaily(){
    this.dashboardService
      .getSelling(1)
      .subscribe(
        (res) => {
          this.sallingDeally=res;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataTablesResponse } from "src/app/classes/dataTablesResponse";
import { endPoint, getToken } from "src/app/classes/endpoints";
import { Country } from "src/app/models/country";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CountryService {
  constructor(private http: HttpClient) {}

  public getCity(search): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get(
      `${endPoint.getCountry}?page=0&size=10000000` + search,
      { headers: headerss }
    );
  }
  
  public getCountry(pages, size, search): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get<DataTablesResponse>(
      `${endPoint.getCountry}` + pages + size + search,
      { headers: headerss }
    );
  }

  public postCountry(countryData: Country) {
    return this.http.post(`${endPoint.postCountry}`, countryData, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
  public deleteCountry(id) {
    return this.http.delete(`${endPoint.dropCountry}`+id, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
  
  public getbyIdCountry(id): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get(
      `${endPoint.getbyCountry}` + id,
      { headers: headerss }
    );
  }
  
  public putCountry(countryData: Country, id) {
    return this.http.put(`${endPoint.putbyCountry}`+ id, countryData, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`,
        "Content-type": "application/json",
      }),
    });
  }
  
  public getCityCountry(id): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get(
      `${endPoint.getCitybyCountry}` + id,
      { headers: headerss }
    );
  }
}

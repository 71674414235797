import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endPoint, getToken } from 'src/app/classes/endpoints';
import { File } from 'src/app/models/file';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) {}

  public postFiles(fileData :any) {
    const formData = new FormData();;
    formData.append("file", fileData);
    return this.http.post(`${endPoint.file}`, formData, {
      headers: new HttpHeaders({
        Authorization: "bearer " + getToken.token,
        "api-key":`${environment.service_api_key}`
      }),
    });
  }
}

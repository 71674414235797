import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTablesResponse } from 'src/app/classes/dataTablesResponse';
import { endPoint, getToken } from 'src/app/classes/endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PriceTemplateService {

  constructor(private http: HttpClient) {}

  public getPriceTemplate(pages, size, search): Observable<any> {
    let headerss = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("api-key", `${environment.service_api_key}`)
      .set("Authorization", "bearer " + getToken.token);
    return this.http.get<DataTablesResponse>(
      `${endPoint.getPriceTemplate}` + pages + size + search,
      { headers: headerss }
    );
  }

}

import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Helper } from "src/app/helper/helper";
import { AirportService } from "src/app/service/airport/airport.service";
import { CityserviceService } from "src/app/service/city/cityservice.service";
import { CountryService } from "src/app/service/country/country.service";

@Component({
  selector: "app-edit-airport",
  templateUrl: "./edit-airport.component.html",
  styleUrls: ["./edit-airport.component.css"],
})
export class EditAirportComponent implements OnInit {
  showLoading = false;
  id: number;
  data: any = {};

  citys: any;
  country: any;

  myOptions: any;
  myOptionsCountry: any;
  selectedCity: any;
  selectedCountry: any;

  myForm = new FormGroup({
    cityId: new FormControl("", [Validators.required]),
    code: new FormControl("", [Validators.required]),
    countryId: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });

  constructor(
    private route: ActivatedRoute,
    private serviceAirport: AirportService,
    public helper: Helper,
    private routes: Router,
    private cityService: CityserviceService,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.getbyIdAirport();
    this.getAirportchange("");
    this.getAirportchangeCountry("");
  }
  get f() {
    return this.myForm.controls;
  }
  submit() {
    if (this.myForm.invalid) {
      return;
    }
    this.showLoading = true;
    this.id = this.route.snapshot.params["id"];
    this.serviceAirport.putAirport(this.myForm.value, this.id).subscribe(
      (res) => {
        this.helper.alertStatus(200, "airport has been save");
        this.showLoading = false;
        this.routes.navigateByUrl("/manage/airport/index");
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
  getbyIdAirport() {
    this.id = this.route.snapshot.params["id"];
    this.serviceAirport.getbyIdAirport(this.id).subscribe(
      (res) => {
        this.data = res;
        let datas = {
          id: res.city.id,
          name: res.city.name + "-" + res.city.id,
        };
        this.selectedCity = res.city.id;
        this.selectedCountry = res.country.id;
        this.getAirportchange(res.city.name);
        this.getAirportchangeCountry(res.country.name);
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }

  getAirportchange(e) {
    this.cityService
      .getCity(`?page=${0}`, `&key=${e}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name + "-" + item.code,
            };
            arr.push(data);
          });
          this.myOptions = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  onSearchCity(searchValue) {
    this.cityService
      .getCity(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name + "-" + item.code,
            };
            arr.push(data);
          });
          this.myOptions = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  onChangeCity(event: string) {
    this.citys = event;
  }

  getAirportchangeCountry(e) {
    this.countryService
      .getCountry(`?page=${0}`, `&key=${e}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name + "-" + item.code,
            };
            arr.push(data);
          });
          this.myOptionsCountry = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  onSearchCountry(searchValue) {
    this.countryService
      .getCountry(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name + "-" + item.code,
            };
            arr.push(data);
          });
          this.myOptionsCountry = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  onChangeCountry(event: string) {
    this.country = event;
  }
}

<div id="kt_body" style="background-image: url(assets/media/bg/bg-1.jpg); height: 140%;"
  class="quick-panel-right demo-panel-right offcanvas-right header-fixed subheader-enabled page-loading">

  <!--begin::Login-->
  <div class="login login-3 login-signin-on d-flex flex-row-fluid">
    <div class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid">
      <div class="login-form text-center text-white p-7 position-relative overflow-hidden">
        <!--begin::Login Header-->
        <div class="d-flex flex-center mb-15">
          <a href="#">
            <img src="assets/mydigiLogo.png" class="max-h-100px" alt="" />
          </a>
        </div>
        <!--end::Login Header-->
        <!--begin::Login Sign in form-->
        <div class="login-signin">
          <div class="mb-20">
            <h3>Sign In To Admin</h3>
            <p class="opacity-60 font-weight-bold">Enter your details to login to your account:</p>
          </div>
          <form [formGroup]="authForm" class="login-container" (ngSubmit)="signIn()">
            <div class="form-group">
              <p [ngClass]="{ 'has-error': isSubmitted && formControls.username.errors }">
                <input type="username" placeholder="username" formControlName="username" class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5">
              </p>
            </div>
            <div *ngIf="isSubmitted && formControls.username.errors" class="help-block">
              <div *ngIf="formControls.username.errors.required">username is required</div>
            </div>
            <div class="form-group">
              <p [ngClass]="{ 'has-error': isSubmitted && formControls.password.errors }">
                <input type="password" placeholder="Password" formControlName="password" class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5">
              </p>
            </div>
            <div *ngIf="isSubmitted && formControls.password.errors" class="help-block">
              <div *ngIf="formControls.password.errors.required">Password is required</div>
            </div>
            <div class="form-group text-center mt-10">
              <button type="submit" id="kt_login_signin_submit"
                class="btn btn-pill btn-outline-white font-weight-bold opacity-90 px-15 py-3">Sign In</button>
            </div>
          </form>
        </div>
        <!--end::Login Sign in form-->
      </div>
    </div>
  </div>
  <!--end::Login-->
</div>

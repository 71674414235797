import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/helper';
import { Category } from 'src/app/models/category';
import { CategoryService } from 'src/app/service/category/category.service';

@Component({
  selector: 'app-index-categories',
  templateUrl: './index-categories.component.html',
  styleUrls: ['./index-categories.component.css']
})
export class IndexCategoriesComponent implements OnInit {

  showLoading = false;
  dtOptions: DataTables.Settings = {};
  category: Category[] = [];

  constructor(
    private categoryService:CategoryService,
    public helper:Helper,
    private router:Router) { }

  ngOnInit(): void {
    this.getAllCategory()
  }
  deletePost(e){
    
  }
  getAllCategory(){
    this.showLoading = true;
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const pages =
          "" + dataTablesParameters.search.value === ""
            ? "?page=" + (dataTablesParameters.draw - 1) + ""
            : "?page=0" + "";
        const size = "&size=" + dataTablesParameters.length + "";
        const search =
          dataTablesParameters.search.value === ""
            ? ""
            : "&key=" + dataTablesParameters.search.value + "";
        this.categoryService.showCategory(pages, size, search).subscribe((res) => {
          that.category = res.content;
          this.showLoading = false;
          callback({
            recordsTotal: res.totalElements,
            recordsFiltered: res.totalPages,
            data: [],
          });
        });
      },
    };
  }
}

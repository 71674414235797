import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./classes/auth.guard";
import { CreateAirlineComponent } from "./pages/airline/create-airline/create-airline.component";
import { EditAirlineComponent } from "./pages/airline/edit-airline/edit-airline.component";
import { IndexAirlineComponent } from "./pages/airline/index-airline/index-airline.component";
import { CreateAirportComponent } from "./pages/airport/create-airport/create-airport.component";
import { EditAirportComponent } from "./pages/airport/edit-airport/edit-airport.component";
import { IndexAirportComponent } from "./pages/airport/index-airport/index-airport.component";
import { CityComponent } from "./pages/city/city.component";
import { IndexCompanyComponent } from "./pages/company/index-company/index-company.component";
import { CreateComponent } from "./pages/country/create/create.component";
import { EditComponent } from "./pages/country/edit/edit.component";
import { IndexComponent } from "./pages/country/index/index.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { LoginComponent } from "./pages/login/login.component";
import { CabinComponent } from "./pages/cabin/cabin.component";
import { CreateCompanyComponent } from "./pages/company/create-company/create-company.component";
import { IndexCategoriesComponent } from "./pages/categories/index-categories/index-categories.component";
import { CreateCategoriesComponent } from "./pages/categories/create-categories/create-categories.component";
import { UpdateCategoriesComponent } from "./pages/categories/update-categories/update-categories.component";
import { IndexCurrencyComponent } from "./pages/currency/index-currency/index-currency.component";
import { CreateCurrencyComponent } from "./pages/currency/create-currency/create-currency.component";
import { EditCurrencyComponent } from "./pages/currency/edit-currency/edit-currency.component";
import { IndexPriceTemplatesComponent } from "./pages/priceTemplates/index-price-templates/index-price-templates.component";
import { CreatePriceTemplatesComponent } from "./pages/priceTemplates/create-price-templates/create-price-templates.component";
import { EditPriceTemplatesComponent } from "./pages/priceTemplates/edit-price-templates/edit-price-templates.component";
import { IndexCompanyTabComponent } from "./pages/companyTab/index-company-tab/index-company-tab.component";
import { IndexProductComponent } from "./pages/product/index-product/index-product.component";
import { CreateProductComponent } from "./pages/product/create-product/create-product.component";
import { EditProductComponent } from "./pages/product/edit-product/edit-product.component";
import { IndexDepartmentsComponent } from "./pages/departments/index-departments/index-departments.component";
import { CreateDepartmentsComponent } from "./pages/departments/create-departments/create-departments.component";
import { UpdateDepartmentsComponent } from "./pages/departments/update-departments/update-departments.component";
import { IndexInterpaceComponent } from "./pages/interpace/index-interpace/index-interpace.component";
import { CreateInterpaceComponent } from "./pages/interpace/create-interpace/create-interpace.component";
import { UpdateInterpaceComponent } from "./pages/interpace/update-interpace/update-interpace.component";
import { IndexBranchComponent } from "./pages/branch/index-branch/index-branch.component";
import { CreateBranchComponent } from "./pages/branch/create-branch/create-branch.component";
import { UpdateBranchComponent } from "./pages/branch/update-branch/update-branch.component";
import { IndexCompanyGroupComponent } from "./pages/company-group/index-company-group/index-company-group.component";
import { CreateCompanyGroupComponent } from "./pages/company-group/create-company-group/create-company-group.component";
import { EditCompanyGroupComponent } from "./pages/company-group/edit-company-group/edit-company-group.component";

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "/" },
  { path: "", component: LoginComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "manage",
    children: [
      {
        path: "country",
        children: [
          {
            path: "index",
            component: IndexComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "create",
            component: CreateComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "update/:id",
            component: EditComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "city",
        children: [
          { path: "index", component: CityComponent, canActivate: [AuthGuard] },
        ],
      },
      {
        path: "cabin",
        children: [
          {
            path: "index",
            component: CabinComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "airline",
        children: [
          {
            path: "index",
            component: IndexAirlineComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "create",
            component: CreateAirlineComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "update/:id",
            component: EditAirlineComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "airport",
        children: [
          {
            path: "index",
            component: IndexAirportComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "create",
            component: CreateAirportComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "update/:id",
            component: EditAirportComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "category",
        children: [
          {
            path: "index",
            component: IndexCategoriesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "create",
            component: CreateCategoriesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "update/:id",
            component: UpdateCategoriesComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "price-template",
        children: [
          {
            path: "index",
            component: IndexPriceTemplatesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "create",
            component: CreatePriceTemplatesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "update/:id",
            component: EditPriceTemplatesComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "product",
        children: [
          {
            path: "index",
            component: IndexProductComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "create",
            component: CreateProductComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "update/:id",
            component: EditProductComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "asset",
        children: [
          {
            path: "index",
            component: IndexInterpaceComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "create",
            component: CreateInterpaceComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "update/:id",
            component: UpdateInterpaceComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "branch",
        children: [
          {
            path: "index",
            component: IndexBranchComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "create",
            component: CreateBranchComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "update/:id",
            component: UpdateBranchComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "depertement",
        children: [
          {
            path: "index",
            component: IndexDepartmentsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "create",
            component: CreateDepartmentsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "update/:id",
            component: UpdateDepartmentsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "currencies",
        children: [
          {
            path: "index",
            component: IndexCurrencyComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "create",
            component: CreateCurrencyComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "update/:id",
            component: EditCurrencyComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "company",
        children: [
          {
            path: "index",
            component: IndexCompanyTabComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "create",
            component: CreateCompanyComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "company-group",
        children: [
          {
            path: "index",
            component: IndexCompanyGroupComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "create",
            component: CreateCompanyGroupComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "update/:id",
            component: EditCompanyGroupComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Helper } from "src/app/helper/helper";
import { CurrencyService } from "src/app/service/currency/currency.service";

@Component({
  selector: "app-create-currency",
  templateUrl: "./create-currency.component.html",
  styleUrls: ["./create-currency.component.css"],
})
export class CreateCurrencyComponent implements OnInit {
  showLoading = false;

  myForm = new FormGroup({
    code: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });
  constructor(
    private currencyService: CurrencyService,
    public helper: Helper,
    private router: Router
  ) {}

  get f() {
    return this.myForm.controls;
  }
  ngOnInit(): void {}

  
  submit() {
    if (this.myForm.invalid) {
      return;
    }
    this.showLoading = true;
    this.currencyService.postCurrency(this.myForm.value).subscribe(
      (res) => {
        this.helper.alertStatus(200, "city has been save");
        this.showLoading = false;
        this.router.navigateByUrl("/manage/currencies/index");
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
}

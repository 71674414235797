<!--begin::Body-->
<div id="kt_body" style="background-image: url(assets/media/bg/bg-10.jpg); height: 400px;"
  class="quick-panel-right demo-panel-right offcanvas-right header-fixed subheader-enabled page-loading">

  <app-header></app-header>
  <app-header-mobile></app-header-mobile>
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="d-flex flex-row flex-column-fluid page">
      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Subheader-->
          <div class="subheader py-2 py-lg-12 subheader-transparent" id="kt_subheader">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              <!--begin::Info-->
              <div class="d-flex align-items-center flex-wrap mr-1">
                <!--begin::Heading-->
                <div class="d-flex flex-column">
                  <!--begin::Title-->
                  <h2 class="text-white font-weight-bold my-2 mr-5">Dashboard</h2>
                  <!--end::Title-->
                  <!--begin::Breadcrumb-->
                  <div class="d-flex align-items-center font-weight-bold my-2">
                    <!--begin::Item-->
                    <a href="#" class="opacity-75 hover-opacity-100">
                      <i class="flaticon2-shelter text-white icon-1x"></i>
                    </a>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <span class="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                    <a href="" class="text-white text-hover-white opacity-75 hover-opacity-100">Dashboard</a>
                    <!--end::Item-->
                    <!--begin::Item-->
                  </div>
                  <!--end::Breadcrumb-->
                </div>
                <!--end::Heading-->
              </div>
              <!--end::Info-->
            </div>
          </div>
          <!--end::Subheader-->
          <!--begin::Entry-->
          <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
              <!--begin::Dashboard-->
              <div class="row">
                <div class="col-xl-6">
                  <!--begin::Mixed Widget 14-->
                  <div class="row">
                    <div class="col-xl-6">
                      <!--begin::Tiles Widget 12-->
                      <div class="card card-custom gutter-b" style="height: 150px">
                        <div class="card-body">
                          <span class="svg-icon svg-icon-3x svg-icon-success">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path
                                  d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                  fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path
                                  d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                  fill="#000000" fill-rule="nonzero"></path>
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          <div class="text-dark font-weight-bolder font-size-h2 mt-3">Rp
                            {{salling3Months.totalSales | number:'1.0':'en-US'}}</div>
                          <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">Selling
                            {{salling3Months.period}}</a>
                        </div>
                      </div>
                      <!--end::Tiles Widget 12-->
                    </div>
                    <div class="col-xl-6">
                      <!--begin::Tiles Widget 12-->
                      <div class="card card-custom gutter-b" style="height: 150px">
                        <div class="card-body">
                          <span class="svg-icon svg-icon-3x svg-icon-success">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path
                                  d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                  fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path
                                  d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                  fill="#000000" fill-rule="nonzero"></path>
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          <div class="text-dark font-weight-bolder font-size-h2 mt-3">Rp
                            {{sallingMonths.totalSales | number:'1.0':'en-US'}}</div>
                          <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">Selling
                            {{sallingMonths.period}}</a>
                        </div>
                      </div>
                      <!--end::Tiles Widget 12-->
                    </div>
                  </div>
                  <!--end::Mixed Widget 14-->
                </div>
                <div class="col-xl-6">
                  <!--end::Tiles Widget 13-->
                  <div class="row">
                    <div class="col-xl-6">
                      <!--begin::Tiles Widget 12-->
                      <div class="card card-custom gutter-b" style="height: 150px">
                        <div class="card-body">
                          <span class="svg-icon svg-icon-3x svg-icon-success">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path
                                  d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                  fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path
                                  d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                  fill="#000000" fill-rule="nonzero"></path>
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          <div class="text-dark font-weight-bolder font-size-h2 mt-3">Rp
                            {{sallingWeekly.totalSales | number:'1.0':'en-US'}}</div>
                          <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">Selling
                            {{sallingWeekly.period}}</a>
                        </div>
                      </div>
                      <!--end::Tiles Widget 12-->
                    </div>
                    <div class="col-xl-6">
                      <!--begin::Tiles Widget 12-->
                      <div class="card card-custom gutter-b" style="height: 150px">
                        <div class="card-body">
                          <span class="svg-icon svg-icon-3x svg-icon-success">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path
                                  d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                  fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path
                                  d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                  fill="#000000" fill-rule="nonzero"></path>
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          <div class="text-dark font-weight-bolder font-size-h2 mt-3">Rp
                            {{sallingDeally.totalSales | number:'1.0':'en-US'}}</div>
                          <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">Selling
                            {{sallingDeally.period}}</a>
                        </div>
                      </div>
                      <!--end::Tiles Widget 12-->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <!--end::Tiles Widget 13-->
                  <div class="row">
                    <div class="col-xl-6">
                      <!--begin::Tiles Widget 12-->
                      <div class="card card-custom gutter-b" style="height: 150px">
                        <div class="card-body">
                          <span class="svg-icon svg-icon-3x svg-icon-success">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path
                                  d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                  fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path
                                  d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                  fill="#000000" fill-rule="nonzero"></path>
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          <div class="text-dark font-weight-bolder font-size-h2 mt-3">Rp
                            {{totalDomestikFlight.totalSales | number:'1.0':'en-US'}}</div>
                          <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">Flight
                            Domestic {{totalDomestikFlight.period}}</a>
                        </div>
                      </div>
                      <!--end::Tiles Widget 12-->
                    </div>
                    <div class="col-xl-6">
                      <!--begin::Tiles Widget 12-->
                      <div class="card card-custom gutter-b" style="height: 150px">
                        <div class="card-body">
                          <span class="svg-icon svg-icon-3x svg-icon-success">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path
                                  d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                  fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path
                                  d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                  fill="#000000" fill-rule="nonzero"></path>
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          <div class="text-dark font-weight-bolder font-size-h2 mt-3">Rp
                            {{totalDomestikHotel.totalSales | number:'1.0':'en-US'}}</div>
                          <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">Hotel
                            Domestic {{totalDomestikHotel.period}}</a>
                        </div>
                      </div>
                      <!--end::Tiles Widget 12-->
                    </div>
                  </div>
                </div>
                <div class="col-xl-6">
                  <!--begin::Mixed Widget 14-->
                  <div class="row">
                    <div class="col-xl-6">
                      <!--begin::Tiles Widget 12-->
                      <div class="card card-custom gutter-b" style="height: 150px">
                        <div class="card-body">
                          <span class="svg-icon svg-icon-3x svg-icon-success">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path
                                  d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                  fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path
                                  d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                  fill="#000000" fill-rule="nonzero"></path>
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          <div class="text-dark font-weight-bolder font-size-h2 mt-3">Rp
                            {{totalInternationalHotel.totalSales | number:'1.0':'en-US'}}</div>
                          <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">Hotel
                            International {{totalInternationalHotel.period}}</a>
                        </div>
                      </div>
                      <!--end::Tiles Widget 12-->
                    </div>
                    <div class="col-xl-6">
                      <!--begin::Tiles Widget 12-->
                      <div class="card card-custom gutter-b" style="height: 150px">
                        <div class="card-body">
                          <span class="svg-icon svg-icon-3x svg-icon-success">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path
                                  d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                  fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path
                                  d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                  fill="#000000" fill-rule="nonzero"></path>
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                          <div class="text-dark font-weight-bolder font-size-h2 mt-3">Rp
                            {{totalInternationalFlight.totalSales | number:'1.0':'en-US'}}</div>
                          <a href="#" class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">Flight
                            International {{totalInternationalFlight.period}}</a>
                        </div>
                      </div>
                      <!--end::Tiles Widget 12-->
                    </div>
                  </div>
                  <!--end::Mixed Widget 14-->
                </div>
              </div>
              <!--begin::Row-->
              <div class="row">
                <div class="col-lg-6 col-xxl-4">
                  <!--begin::Mixed Widget 4-->
                  <div class="card card-custom bg-radial-gradient-danger gutter-b card-stretch">
                    <!--begin::Header-->
                    <div class="card-header border-0 py-5">
                      <h3 class="card-title font-weight-bolder text-white">Sales Progress</h3>
                      <div class="card-toolbar">
                        <div class="dropdown dropdown-inline">
                        </div>
                      </div>
                    </div>
                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body d-flex flex-column p-0">
                      <!--begin::Stats-->
                      <div class="card-spacer bg-white card-rounded flex-grow-1">
                        <ngb-tabset [destroyOnHide]="false">
                          <ngb-tab>
                            <ng-template ngbTabTitle>Flight</ng-template>
                            <ng-template ngbTabContent>
                              <table class="table table-head-custom table-vertical-center"
                                id="kt_advance_table_widget_1">
                                <thead>
                                  <tr class="text-left">
                                    <th style="min-width: 150px">Company</th>
                                    <th style="min-width: 150px">Sale</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let topFlight of topCompanysFlight">
                                    <td>
                                      {{topFlight.company}}
                                    </td>
                                    <td>
                                      Rp {{topFlight.totalSale | number:'1.0':'en-US'}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </ng-template>
                          </ngb-tab>
                          <ngb-tab>
                            <ng-template ngbTabTitle>Hotel</ng-template>
                            <ng-template ngbTabContent>

                              <table class="table table-head-custom table-vertical-center"
                                id="kt_advance_table_widget_1">
                                <thead>
                                  <tr class="text-left">
                                    <th style="min-width: 150px">Company</th>
                                    <th style="min-width: 150px">Sale</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let tops of topCompanys">
                                    <td>
                                      {{tops.company}}
                                    </td>
                                    <td>
                                      Rp {{tops.totalSale | number:'1.0':'en-US'}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </ng-template>
                          </ngb-tab>
                        </ngb-tabset>
                      </div>
                      <!--end::Stats-->
                    </div>
                    <!--end::Body-->
                  </div>
                  <!--end::Mixed Widget 4-->
                </div>
                <div class="col-lg-6 col-xxl-8">
                  <!--begin::Advance Table Widget 1-->
                  <div class="card card-custom card-stretch gutter-b">
                    <!--begin::Header-->
                    <div class="card-header border-0 py-5">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Latest Booking</span>
                      </h3>
                    </div>
                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body py-0">
                      <!--begin::Table-->
                      <div class="table-responsive">

                        <!--begin: Datatable-->

                        <table datatable [dtOptions]="dtOptions" class="row-border hover">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Booking Number</th>
                              <th>Company</th>
                              <th>Create</th>
                              <th>Expired</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody *ngIf="faildInvoice?.length != 0">
                            <tr *ngFor="let faile of faildInvoice">
                              <td>{{ faile.id }}</td>
                              <td>{{ faile.bookingNumber }}</td>
                              <td>{{ faile.company.name}}</td>
                              <td>{{ faile.createdAt}}</td>
                              <td>{{ faile.expiredAt}}</td>
                              <td><a [routerLink]="['/manage/airport/update/', faile.id]"
                                  routerLinkActive="router-link-active" class="btn btn-sm btn-clean btn-icon mr-2"
                                  title="Edit details">
                                  <span class="svg-icon svg-icon-md"> <svg xmlns="http://www.w3.org/2000/svg"
                                      xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                      viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <path
                                          d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                          fill="#000000" fill-rule="nonzero"
                                          transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) ">
                                        </path>
                                        <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1">
                                        </rect>
                                      </g>
                                    </svg> </span> </a> </td>
                            </tr>
                          </tbody>
                          <tbody *ngIf="faildInvoice?.length == 0">
                            <tr>
                              <td colspan="3" class="no-data-available">No data!</td>
                            </tr>
                          </tbody>
                        </table>
                        <!--end: Datatable-->
                        <!-- <table class="table table-head-custom table-vertical-center" id="kt_advance_table_widget_1">
                          <thead>
                            <tr class="text-left">
                              <th class="pr-0" style="width: 150px">Booking Number</th>
                              <th style="min-width: 150px">Date</th>
                              <th style="min-width: 150px">Company</th>
                              <th style="min-width: 150px">Status</th>
                              <th class="pr-0 text-right" style="min-width: 150px">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="pr-0">
                                <span class="text-dark-75 font-weight-bolder d-block font-size-lg">BKMD000001</span>
                              </td>
                              <td>
                                <span class="text-dark-75 font-weight-bolder d-block font-size-lg">17-05-2021</span>
                              </td>
                              <td>
                                <span class="text-dark-75 font-weight-bolder d-block font-size-lg">My Tours Digi</span>
                              </td>
                              <td>
                                <div class="d-flex flex-column w-100 mr-2">
                                  <div class="d-flex align-items-center justify-content-between mb-2">
                                    <span class="text-muted mr-2 font-size-sm font-weight-bold">65%</span>
                                    <span class="text-muted font-size-sm font-weight-bold">Progress</span>
                                  </div>
                                  <div class="progress progress-xs w-100">
                                    <div class="progress-bar bg-danger" role="progressbar" style="width: 65%;"
                                      aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </div>
                              </td>
                              <td class="pr-0 text-right">
                                <a href="#" class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                                  <span class="svg-icon svg-icon-md svg-icon-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                      width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path
                                          d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                          fill="#000000" fill-rule="nonzero"
                                          transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)" />
                                        <path
                                          d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                          fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                      </g>
                                    </svg>
                                  </span>
                                </a>
                                <a href="#" class="btn btn-icon btn-light btn-hover-primary btn-sm">
                                  <span class="svg-icon svg-icon-md svg-icon-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                      width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path
                                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                          fill="#000000" fill-rule="nonzero" />
                                        <path
                                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                          fill="#000000" opacity="0.3" />
                                      </g>
                                    </svg>
                                  </span>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table> -->
                      </div>
                      <!--end::Table-->
                    </div>
                    <!--end::Body-->
                  </div>
                  <!--end::Advance Table Widget 1-->
                </div>
              </div>
              <!--end::Row-->
              <!--end::Dashboard-->
            </div>
            <!--end::Container-->
          </div>
          <!--end::Entry-->
        </div>
        <!--end::Content-->
        <app-footer></app-footer>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
</div>

<!--end::Main-->

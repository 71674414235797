import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from  '@angular/router';
import { User } from '../../models/user';
import { AuthService } from '../../service/auth.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, private formBuilder: FormBuilder ) { }
  
  authForm: FormGroup;
  isSubmitted  =  false;

  ngOnInit(): void {
    this.authForm  =  this.formBuilder.group({
        username: ['', Validators.required],
        grant_type: ['password'],
        password: ['', Validators.required]
    });
  }

  get formControls() { return this.authForm.controls; }

  signIn(){
    this.isSubmitted = true;
    if(this.authForm.invalid){
      return;
    }
    this.authService.signIn(this.authForm.value).subscribe(
      res => {
        this.authService.setAuthorization(res);
        window.location.href='/dashboard'
      },
      err => {
        console.log(err)
      }
    );
  }

}

<!--begin::Body-->
<div id="kt_body" style="background-image: url(assets/media/bg/bg-10.jpg); height: 400px;"
  class="quick-panel-right demo-panel-right offcanvas-right header-fixed subheader-enabled page-loading">

  <app-header></app-header>
  <app-header-mobile></app-header-mobile>
  <div class="loading" id="loading-image" *ngIf="showLoading">Loading&#8230;</div>
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="d-flex flex-row flex-column-fluid page">
      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Subheader-->
          <div class="subheader py-2 py-lg-12 subheader-transparent" id="kt_subheader">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              <!--begin::Info-->
              <div class="d-flex align-items-center flex-wrap mr-1">
                <!--begin::Heading-->
                <div class="d-flex flex-column">
                  <!--begin::Title-->
                  <h2 class="text-white font-weight-bold my-2 mr-5">Branch</h2>
                  <!--end::Title-->
                  <!--begin::Breadcrumb-->
                  <div class="d-flex align-items-center font-weight-bold my-2">
                    <!--begin::Item-->
                    <a href="#" class="opacity-75 hover-opacity-100">
                      <i class="flaticon2-shelter text-white icon-1x"></i>
                    </a>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <span class="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                    <a [routerLink]="['/admin']" routerLinkActive="router-link-active"
                      class="text-white text-hover-white opacity-75 hover-opacity-100">Dashboard</a>
                    <!--end::Item-->
                    <span class="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                    <a href="#" class="text-white text-hover-white opacity-75 hover-opacity-100">Branch</a>
                    <!--end::Item-->
                    <!--begin::Item-->
                  </div>
                  <!--end::Breadcrumb-->
                </div>
                <!--end::Heading-->
              </div>
              <!--end::Info-->
            </div>
          </div>
          <!--end::Subheader-->

          <!--begin::Entry-->
          <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
              <!--begin::Card-->
              <div class="card card-custom">
                <div class="card-header flex-wrap border-0 pt-6 pb-0">
                  <div class="card-title">
                    <h3 class="card-label">Branch Create</h3>
                  </div>
                  <div class="card-toolbar">
                  </div>
                </div>
                <div class="card-body">
                  <!--begin: Search Form-->
                  <!--end: Search Form-->
                  <!--begin: Datatable-->
                  <form [formGroup]="myForm" (ngSubmit)="submit()">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group row">
                          <label class="col-2 col-form-label">Code</label>
                          <div class="col-10">
                            <input class="form-control" formControlName="code" type="text" id="code"
                              placeholder="enter code" />
                            <div *ngIf="f.code.touched && f.code.invalid" class="alert alert-danger">
                              <div *ngIf="f.code.errors.required">code is required.</div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-2 col-form-label">Name</label>
                          <div class="col-10">
                            <input class="form-control" formControlName="name" type="text" id="name"
                              placeholder="enter name" />
                            <div *ngIf="f.name.touched && f.name.invalid" class="alert alert-danger">
                              <div *ngIf="f.name.errors.required">Number is required.</div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-2 col-form-label">No</label>
                          <div class="col-10">
                            <input class="form-control" formControlName="no" type="text" id="no"
                              placeholder="enter no" />
                            <div *ngIf="f.no.touched && f.no.invalid" class="alert alert-danger">
                              <div *ngIf="f.no.errors.required">Number is required.</div>
                            </div>
                          </div>
                        </div>

                        
                    <div class="form-group row">
                        <label class="col-2 col-form-label">Status</label>
                        <div class="col-10">
  
                          <div class="form-check">
                            <input class="form-check-input" type="radio" formControlName="branchStatus" id="branchStatus" value="1">
                            <label class="form-check-label" for="exampleRadios1">
                              Active
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" formControlName="branchStatus" id="branchStatus" value="2">
                            <label class="form-check-label" for="exampleRadios1">
                              In Active
                            </label>
                          </div>
                          <div *ngIf="f.branchStatus.touched && f.branchStatus.invalid" class="alert alert-danger">
                            <div *ngIf="f.branchStatus.errors.required">status is required.</div>
                          </div>
                        </div>
                      </div>


                      <div class="form-group row">
                        <label class="col-2 col-form-label">Address</label>
                        <div class="col-10">
                            <textarea class="form-control" formControlName="address"></textarea>
                          <div *ngIf="f.address.touched && f.address.invalid" class="alert alert-danger">
                            <div *ngIf="f.address.errors.required">address is required.</div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-2 col-form-label">Type</label>
                        <div class="col-10">
                          <input class="form-control" formControlName="type" type="text" id="type"
                            placeholder="enter type" />
                          <div *ngIf="f.type.touched && f.type.invalid" class="alert alert-danger">
                            <div *ngIf="f.type.errors.required">type is required.</div>
                          </div>
                        </div>
                      </div>
                        <div class="form-group row">
                          <label for="dateOfBirth " class="col-2">Country</label>
                          <div class="input-group col-10">
                            <ng-select placeholder="Select some items" [items]="myOptionsCountry"
                              formControlName="countryId" bindLabel="name" bindValue="id"
                              (search)="onSearchCountry($event)" (change)="onChangeCountry($event)">
                            </ng-select>
                          </div>
                          <div *ngIf="f.countryId.touched && f.countryId.invalid" class="alert alert-danger">
                            <div *ngIf="f.countryId.errors.required">country is required.</div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="dateOfBirth" class="col-2 col-form-label">City</label>
                          <div class="input-group col-10">
                            <ng-select placeholder="Select some items" [items]="myOptions" formControlName="cityId"
                              bindLabel="name" bindValue="id"
                              (change)="onChangeCity($event)">
                            </ng-select>
                            <div *ngIf="f.cityId.touched && f.cityId.invalid" class="alert alert-danger">
                              <div *ngIf="f.cityId.errors.required">country is required.</div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="form-group row">
                          <label for="dateOfBirth " class="col-2">Currency</label>
                          <div class="input-group col-10">
                            <ng-select placeholder="Select some items" [items]="myOptionsCurrency"
                              formControlName="currencyId" bindLabel="name" bindValue="id"
                              (search)="onSearchCurrency($event)">
                            </ng-select>
                          </div>
                          <div *ngIf="f.currencyId.touched && f.currencyId.invalid" class="alert alert-danger">
                            <div *ngIf="f.currencyId.errors.required">currency is required.</div>
                          </div>
                        </div>

                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-12">

                        <div class="form-group row">
                          <label class="col-2 col-form-label">Email</label>
                          <div class="col-10">
                            <input class="form-control" formControlName="email" type="text" id="email"
                              placeholder="enter email" />
                            <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger">
                              <div *ngIf="f.email.errors.required">email is required.</div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="form-group row">
                          <label class="col-2 col-form-label">Phone</label>
                          <div class="col-10">
                            <input class="form-control" formControlName="phone" type="text" id="phone"
                              placeholder="enter phone" />
                            <div *ngIf="f.phone.touched && f.phone.invalid" class="alert alert-danger">
                              <div *ngIf="f.phone.errors.required">phone is required.</div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-2 col-form-label">Second phone</label>
                          <div class="col-10">
                            <input class="form-control" formControlName="phone1" type="text" id="phone1"
                              placeholder="enter phone1" />
                            <div *ngIf="f.phone1.touched && f.phone1.invalid" class="alert alert-danger">
                              <div *ngIf="f.phone1.errors.required">phone1 is required.</div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-2 col-form-label">Fax</label>
                          <div class="col-10">
                            <input class="form-control" formControlName="fax" type="text" id="fax"
                              placeholder="enter fax" />
                            <div *ngIf="f.fax.touched && f.fax.invalid" class="alert alert-danger">
                              <div *ngIf="f.fax.errors.required">fax is required.</div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-2 col-form-label">Post Code</label>
                          <div class="col-10">
                            <input class="form-control" formControlName="postcode" type="text" id="postcode"
                              placeholder="enter postcode" />
                            <div *ngIf="f.postcode.touched && f.postcode.invalid" class="alert alert-danger">
                              <div *ngIf="f.postcode.errors.required">postcode is required.</div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-2 col-form-label">No Npwp</label>
                          <div class="col-10">
                            <input class="form-control" formControlName="npwpNo" type="text" id="npwpNo"
                              placeholder="enter npwpNo" />
                            <div *ngIf="f.npwpNo.touched && f.npwpNo.invalid" class="alert alert-danger">
                              <div *ngIf="f.npwpNo.errors.required">npwpNo is required.</div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-2 col-form-label">Npwp Name</label>
                          <div class="col-10">
                            <input class="form-control" formControlName="npwpName" type="text" id="npwpName"
                              placeholder="enter npwpName" />
                            <div *ngIf="f.npwpName.touched && f.npwpName.invalid" class="alert alert-danger">
                              <div *ngIf="f.npwpName.errors.required">npwp name is required.</div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-2 col-form-label">Npwp Address</label>
                          <div class="col-10">
                            <textarea class="form-control" formControlName="npwpAddress"></textarea>
                            <div *ngIf="f.npwpAddress.touched && f.npwpAddress.invalid" class="alert alert-danger">
                              <div *ngIf="f.npwpAddress.errors.required">npwp address is required.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button type="submit" class="btn btn-success mr-2">Submit</button>
                    </div>
                  </form>
                  <!--end: Datatable-->
                </div>
              </div>
              <!--end::Card-->
            </div>
            <!--end::Container-->
          </div>
          <!--end::Entry-->
        </div>
        <!--end::Content-->
        <app-footer></app-footer>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
</div>
<!-- edit -->
<!--end::Main-->

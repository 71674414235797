<!--begin::Body-->
<div id="kt_body" style="background-image: url(assets/media/bg/bg-10.jpg); height: 400px;"
  class="quick-panel-right demo-panel-right offcanvas-right header-fixed subheader-enabled page-loading">

  <app-header></app-header>
  <app-header-mobile></app-header-mobile>
  <div class="loading" id="loading-image" *ngIf="showLoading">Loading&#8230;</div>
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="d-flex flex-row flex-column-fluid page">
      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Subheader-->
          <div class="subheader py-2 py-lg-12 subheader-transparent" id="kt_subheader">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              <!--begin::Info-->
              <div class="d-flex align-items-center flex-wrap mr-1">
                <!--begin::Heading-->
                <div class="d-flex flex-column">
                  <!--begin::Title-->
                  <h2 class="text-white font-weight-bold my-2 mr-5">Airport</h2>
                  <!--end::Title-->
                  <!--begin::Breadcrumb-->
                  <div class="d-flex align-items-center font-weight-bold my-2">
                    <!--begin::Item-->
                    <a href="#" class="opacity-75 hover-opacity-100">
                      <i class="flaticon2-shelter text-white icon-1x"></i>
                    </a>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <span class="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                    <a [routerLink]="['/admin']" routerLinkActive="router-link-active"
                      class="text-white text-hover-white opacity-75 hover-opacity-100">Dashboard</a>
                    <!--end::Item-->
                    <span class="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                    <a href="#" class="text-white text-hover-white opacity-75 hover-opacity-100">Airport</a>
                    <!--end::Item-->
                    <!--begin::Item-->
                  </div>
                  <!--end::Breadcrumb-->
                </div>
                <!--end::Heading-->
              </div>
              <!--end::Info-->
            </div>
          </div>
          <!--end::Subheader-->

          <!--begin::Entry-->
          <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
              <!--begin::Card-->
              <div class="card card-custom">
                <div class="card-header flex-wrap border-0 pt-6 pb-0">
                  <div class="card-title">
                    <h3 class="card-label">Company Create</h3>
                  </div>
                  <div class="card-toolbar">
                  </div>
                </div>
                <div class="card-body">
                  <!--begin: Search Form-->
                  <!--end: Search Form-->
                  <!--begin: Datatable-->
                  <form [formGroup]="myForm" (ngSubmit)="submit()">
                    
                    <div class="form-group row">
                      <label class="col-2 col-form-label">Branch</label>
                      <div class="col-10">
                        <ng-select 
                          placeholder="Select some items" 
                          [items]="branchSelect" 
                          formControlName="branchId"
                          bindLabel="name" 
                          bindValue="id" 
                          (search)="onSearchBranch($event)">
                        </ng-select>
                        <div *ngIf="f.branchId.touched && f.branchId.invalid" class="alert alert-danger">
                          <div *ngIf="f.branchId.errors.required">branchId is required.</div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="form-group row">
                      <label class="col-2 col-form-label">Company Code</label>
                      <div class="col-10">
                        <input class="form-control" formControlName="code" type="text" id="code"
                          placeholder="enter Airline number" />
                        <div *ngIf="f.code.touched && f.code.invalid" class="alert alert-danger">
                          <div *ngIf="f.code.errors.required">Number is required.</div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="form-group row">
                        <label class="col-2 col-form-label">Company Group</label>
                        <div class="col-10">
                          <ng-select 
                            placeholder="Select some items" 
                            [items]="groupSelect" 
                            formControlName="branchId"
                            bindLabel="name" 
                            bindValue="id" 
                            (search)="onSearchGroup($event)">
                          </ng-select>
                          <div *ngIf="f.branchId.touched && f.branchId.invalid" class="alert alert-danger">
                            <div *ngIf="f.branchId.errors.required">branchId is required.</div>
                          </div>
                        </div>
                      </div>
                    <div class="card-footer">
                      <button type="submit" class="btn btn-success mr-2">Submit</button>
                    </div>
                  </form>
                  <!--end: Datatable-->
                </div>
              </div>
              <!--end::Card-->
            </div>
            <!--end::Container-->
          </div>
          <!--end::Entry-->
        </div>
        <!--end::Content-->
        <app-footer></app-footer>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
</div>
<!-- edit -->
<!--end::Main-->

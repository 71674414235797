import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index-company-tab',
  templateUrl: './index-company-tab.component.html',
  styleUrls: ['./index-company-tab.component.css']
})
export class IndexCompanyTabComponent implements OnInit {
  showLoading = false;

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from 'src/app/helper/helper';
import { CountryService } from 'src/app/service/country/country.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {

  showLoading = false;
  id:number;
  data: any = {};
  myForm = new FormGroup({
    call_code: new FormControl("", [Validators.required]),
    code: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });

  constructor(
    private route: ActivatedRoute,
    private serviceCountry:CountryService,
    public helper:Helper,
    private routes:Router) { }

  ngOnInit(): void {
    this.getCountry();
  }
  getCountry(){
    this.id = this.route.snapshot.params['id'];
    this.serviceCountry.getbyIdCountry(this.id).subscribe(
      (res) => {
        console.log(res)
        this.data = res;
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
  submit(){
    if (this.myForm.invalid) {
      return;
    }
    this.showLoading = true;
    this.id = this.route.snapshot.params['id'];
    this.serviceCountry.putCountry(this.myForm.value, this.id).subscribe(
      (res) => {
        this.helper.alertStatus(200, "country has been save");
        this.showLoading = false;
        this.routes.navigateByUrl("/manage/country/index");
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
  
  get f() {
    return this.myForm.controls;
  }
}

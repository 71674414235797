import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Helper } from "src/app/helper/helper";
import { CountryService } from "src/app/service/country/country.service";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.css"],
})
export class CreateComponent implements OnInit {
  showLoading = false;

  myForm = new FormGroup({
    call_code: new FormControl("", [Validators.required]),
    code: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });
  constructor(private countryService: CountryService, public helper: Helper, 
    private router: Router) {}

  ngOnInit(): void {}

  get f() {
    return this.myForm.controls;
  }
  submit() {
    if (this.myForm.invalid) {
      return;
    }
    this.showLoading = true;
    this.countryService.postCountry(this.myForm.value).subscribe(
      (res) => {
        this.helper.alertStatus(200, "city has been save");
        this.showLoading = false;
        this.router.navigateByUrl("/manage/country/index");
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
}

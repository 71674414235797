import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { IOption } from "ng-select";
import { Helper } from "src/app/helper/helper";
import { City } from "src/app/models/city";
import { AirportService } from "src/app/service/airport/airport.service";
import { CityserviceService } from "src/app/service/city/cityservice.service";
import { CountryService } from "src/app/service/country/country.service";

@Component({
  selector: "app-create-airport",
  templateUrl: "./create-airport.component.html",
  styleUrls: ["./create-airport.component.css"],
})
export class CreateAirportComponent implements OnInit {
  showLoading = false;
  selectedItems: any;
  citys: any;
  country: any;
  events: City[] = [];
  selectedCityId: number;
  
  myOptions: any;
  myOptionsCountry: any;
  myForm = new FormGroup({
    cityId: new FormControl("", [Validators.required]),
    code: new FormControl("", [Validators.required]),
    countryId: new FormControl(" ", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });

  constructor(
    private airportService: AirportService,
    private cityService: CityserviceService,
    public helper: Helper,
    private router: Router,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {}

  get f() {
    return this.myForm.controls;
  }
  submit() {
    if (this.myForm.invalid) {
      return;
    }
    this.showLoading = true;
    this.airportService.postAirport(this.myForm.value).subscribe(
      (res) => {
        this.helper.alertStatus(200, "airport has been save");
        this.showLoading = false;
        this.router.navigateByUrl("/manage/airport/index");
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }

  onSearchCity(searchValue) {
    this.cityService
      .getCity(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name + '-' + item.code,
            };
             arr.push(data);
          });
          this.myOptions=arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  onChangeCity(event: string) {
    this.citys=event;
  }

  
  onSearchCountry(searchValue) {
    this.countryService
      .getCountry(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name + '-' + item.code,
            };
             arr.push(data);
          });
          this.myOptionsCountry=arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  onChangeCountry(event: string) {
    this.country=event;
  }
}

import Swal from "sweetalert2";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class Helper {
  public alertStatus(code, message) {
    if (code === 200) {
      Swal.fire({
        icon: "success",
        position: "top-end",
        title: "Success",
        text: message,
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (code === 400) {
      Swal.fire({
        icon: "warning",
        position: "top-end",
        title: "Warning",
        text: message,
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (code === 401) {
      Swal.fire({
        icon: "warning",
        position: "top-end",
        title: "Warning",
        text: "account not authorized",
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (code === 403) {
      Swal.fire({
        icon: "warning",
        position: "top-end",
        title: "Warning",
        text: "account forbidden",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        position: "top-end",
        title: "Error",
        text: "cek your internet",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  public jwtDecode(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { HeaderMobileComponent } from './component/header-mobile/header-mobile.component';
import { HttpClientModule } from '@angular/common/http';
import { CityComponent } from './pages/city/city.component';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectModule } from 'ng-select';
import { Helper } from './helper/helper';
import { IndexComponent } from './pages/country/index/index.component';
import { EditComponent } from './pages/country/edit/edit.component';
import { CreateComponent } from './pages/country/create/create.component';
import { IndexAirlineComponent } from './pages/airline/index-airline/index-airline.component';
import { CreateAirlineComponent } from './pages/airline/create-airline/create-airline.component';
import { EditAirlineComponent } from './pages/airline/edit-airline/edit-airline.component';
import { IndexAirportComponent } from './pages/airport/index-airport/index-airport.component';
import { EditAirportComponent } from './pages/airport/edit-airport/edit-airport.component';
import { CreateAirportComponent } from './pages/airport/create-airport/create-airport.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IndexCompanyComponent } from './pages/company/index-company/index-company.component';
import { CreateCompanyComponent } from './pages/company/create-company/create-company.component';
import { EditCompanyComponent } from './pages/company/edit-company/edit-company.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CabinComponent } from './pages/cabin/cabin.component';
import { IndexCategoriesComponent } from './pages/categories/index-categories/index-categories.component';
import { CreateCategoriesComponent } from './pages/categories/create-categories/create-categories.component';
import { UpdateCategoriesComponent } from './pages/categories/update-categories/update-categories.component';
import { IndexCurrencyComponent } from './pages/currency/index-currency/index-currency.component';
import { EditCurrencyComponent } from './pages/currency/edit-currency/edit-currency.component';
import { CreateCurrencyComponent } from './pages/currency/create-currency/create-currency.component';
import { IndexPriceTemplatesComponent } from './pages/priceTemplates/index-price-templates/index-price-templates.component';
import { CreatePriceTemplatesComponent } from './pages/priceTemplates/create-price-templates/create-price-templates.component';
import { EditPriceTemplatesComponent } from './pages/priceTemplates/edit-price-templates/edit-price-templates.component';
import { IndexCompanyTabComponent } from './pages/companyTab/index-company-tab/index-company-tab.component';
import { IndexProductComponent } from './pages/product/index-product/index-product.component';
import { EditProductComponent } from './pages/product/edit-product/edit-product.component';
import { CreateProductComponent } from './pages/product/create-product/create-product.component';
import { IndexDepartmentsComponent } from './pages/departments/index-departments/index-departments.component';
import { CreateDepartmentsComponent } from './pages/departments/create-departments/create-departments.component';
import { UpdateDepartmentsComponent } from './pages/departments/update-departments/update-departments.component';
import { IndexInterpaceComponent } from './pages/interpace/index-interpace/index-interpace.component';
import { CreateInterpaceComponent } from './pages/interpace/create-interpace/create-interpace.component';
import { UpdateInterpaceComponent } from './pages/interpace/update-interpace/update-interpace.component';
import { IndexBranchComponent } from './pages/branch/index-branch/index-branch.component';
import { UpdateBranchComponent } from './pages/branch/update-branch/update-branch.component';
import { CreateBranchComponent } from './pages/branch/create-branch/create-branch.component';
import { IndexCompanyGroupComponent } from './pages/company-group/index-company-group/index-company-group.component';
import { CreateCompanyGroupComponent } from './pages/company-group/create-company-group/create-company-group.component';
import { EditCompanyGroupComponent } from './pages/company-group/edit-company-group/edit-company-group.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    HeaderMobileComponent,
    CityComponent,
    CabinComponent,
    IndexComponent,
    EditComponent,
    CreateComponent,
    IndexAirlineComponent,
    CreateAirlineComponent,
    EditAirlineComponent,
    IndexAirportComponent,
    EditAirportComponent,
    CreateAirportComponent,
    IndexCompanyComponent,
    CreateCompanyComponent,
    EditCompanyComponent,
    IndexCategoriesComponent,
    CreateCategoriesComponent,
    UpdateCategoriesComponent,
    IndexCurrencyComponent,
    EditCurrencyComponent,
    CreateCurrencyComponent,
    IndexPriceTemplatesComponent,
    CreatePriceTemplatesComponent,
    EditPriceTemplatesComponent,
    IndexCompanyTabComponent,
    IndexProductComponent,
    EditProductComponent,
    CreateProductComponent,
    IndexDepartmentsComponent,
    CreateDepartmentsComponent,
    UpdateDepartmentsComponent,
    IndexInterpaceComponent,
    CreateInterpaceComponent,
    UpdateInterpaceComponent,
    IndexBranchComponent,
    UpdateBranchComponent,
    CreateBranchComponent,
    IndexCompanyGroupComponent,
    CreateCompanyGroupComponent,
    EditCompanyGroupComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RouterModule,
    DataTablesModule,
    NgbModule,
    // SelectModule,
    NgSelectModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

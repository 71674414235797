import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from 'src/app/helper/helper';
import { AirlineService } from 'src/app/service/airline/airline.service';
import { FileService } from 'src/app/service/file/file.service';

@Component({
  selector: 'app-edit-airline',
  templateUrl: './edit-airline.component.html',
  styleUrls: ['./edit-airline.component.css']
})
export class EditAirlineComponent implements OnInit {

  showLoading = false;
  id:number;
  data: any = {};
  myForm = new FormGroup({
    code: new FormControl("", [Validators.required]),
    domesticComm: new FormControl("", [Validators.required]),
    domesticFlag: new FormControl("", [Validators.required]),
    internationalComm: new FormControl("", [Validators.required]),
    internationalFlag: new FormControl("", [Validators.required]),
    logoId: new FormControl(""),
    name: new FormControl("", [Validators.required]),
    no: new FormControl("", [Validators.required]),
    status: new FormControl("", [Validators.required]),
  });

  constructor(
    private route: ActivatedRoute,
    private airlineService: AirlineService, 
    private fileService: FileService, 
    public helper: Helper, 
    private router: Router) { }

  get f() {
    return this.myForm.controls;
  }

  ngOnInit(): void {
    this.getAirline();
  }

  submit(){
  }

  getAirline(){
    this.id = this.route.snapshot.params['id'];
    this.airlineService.getbyIdAirline(this.id).subscribe(
      (res) => {
        console.log(res)
        this.data = res;
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
  
  uploadImage(e){
    this.fileService.postFiles(e.target.files[0]).subscribe(
      (res) => {
        this.data=res;
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
      }
    );
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

import { Md5 } from "ts-md5";

// The list of file replacements can be found in `angular.json`.
const md5 = new Md5();
export const environment = {
  production: false,
  service_digi:'https://devapi.mytours.co.id:3374',
  service_api_key:md5.appendStr(btoa('!!!mYtOuRs2021')).end()
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

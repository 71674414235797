import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from 'src/app/helper/helper';
import { CategoryService } from 'src/app/service/category/category.service';
import { ProductService } from 'src/app/service/product/product.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {
  showLoading = false;
  myOptions: any;
  data: any = {};
  id: number;

  category: any;

  myForm = new FormGroup({
    categoryId: new FormControl("", [Validators.required]),
    code: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });

  constructor(
    private productService: ProductService,
    private categoryService: CategoryService,
    public helper: Helper,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  get f() {
    return this.myForm.controls;
  }

  ngOnInit(): void {
    this.getbyIdProduct();
    this.getCategorychange('');
  }

  getbyIdProduct() {
    this.id = this.route.snapshot.params["id"];
    this.productService.getbyIdProduct(this.id).subscribe(
      (res) => {
        this.data = res;
        this.category = res.category.id;
        this.getCategorychange(res.city.name);
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }

  
  getCategorychange(e) {
    this.categoryService
      .showCategory(`?page=${0}`, `&key=${e}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name,
            };
            arr.push(data);
          });
          this.myOptions = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }

  submit(){
    if (this.myForm.invalid) {
      return;
    }
    this.id = this.route.snapshot.params["id"];
    this.showLoading = true;
    this.productService.putProduct(this.myForm.value, this.id).subscribe(
      (res) => {
        this.helper.alertStatus(200, "product has been save");
        this.showLoading = false;
        this.router.navigateByUrl("/manage/product/index");
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }

  
  onSearchCategory(searchValue) {
    this.categoryService
      .showCategory(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name,
            };
             arr.push(data);
          });
          this.myOptions=arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
}

<!--begin::Body-->
<div id="kt_body" style="background-image: url(assets/media/bg/bg-10.jpg); height: 400px;"
  class="quick-panel-right demo-panel-right offcanvas-right header-fixed subheader-enabled page-loading">

  <app-header></app-header>
  <app-header-mobile></app-header-mobile>
  <div class="loading" id="loading-image" *ngIf="showLoading">Loading&#8230;</div>
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="d-flex flex-row flex-column-fluid page">
      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Subheader-->
          <div class="subheader py-2 py-lg-12 subheader-transparent" id="kt_subheader">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              <!--begin::Info-->
              <div class="d-flex align-items-center flex-wrap mr-1">
                <!--begin::Heading-->
                <div class="d-flex flex-column">
                  <!--begin::Title-->
                  <h2 class="text-white font-weight-bold my-2 mr-5">Branch</h2>
                  <!--end::Title-->
                  <!--begin::Breadcrumb-->
                  <div class="d-flex align-items-center font-weight-bold my-2">
                    <!--begin::Item-->
                    <a href="#" class="opacity-75 hover-opacity-100">
                      <i class="flaticon2-shelter text-white icon-1x"></i>
                    </a>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <span class="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                    <a [routerLink]="['/admin']" routerLinkActive="router-link-active"
                      class="text-white text-hover-white opacity-75 hover-opacity-100">Dashboard</a>
                    <!--end::Item-->
                    <span class="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                    <a href="#" class="text-white text-hover-white opacity-75 hover-opacity-100">branch</a>
                    <!--end::Item-->
                    <!--begin::Item-->
                  </div>
                  <!--end::Breadcrumb-->
                </div>
                <!--end::Heading-->
              </div>
              <!--end::Info-->
            </div>
          </div>
          <!--end::Subheader-->

          <!--begin::Entry-->
          <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
              <!--begin::Card-->
              <div class="card card-custom">
                <div class="card-header flex-wrap border-0 pt-6 pb-0">
                  <div class="card-title">
                    <h3 class="card-label">Branch Datasource</h3>
                  </div>
                  <div class="card-toolbar">
                    <!--begin::Button-->
                    <a [routerLink]="['/manage/branch/create']" routerLinkActive="router-link-active"
                      class="btn btn-primary font-weight-bolder">
                      <span class="svg-icon svg-icon-md">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg-->
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                          height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <circle fill="#000000" cx="9" cy="15" r="6" />
                            <path
                              d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                              fill="#000000" opacity="0.3" />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>New Branch</a>
                    <!--end::Button-->
                  </div>
                </div>
                <div class="card-body">
                  <!--begin: Search Form-->
                  <!--end: Search Form-->
                  <!--begin: Datatable-->

                  <table datatable [dtOptions]="dtOptions" class="row-border hover">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>No</th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="branch?.length != 0">
                      <tr *ngFor="let branchs of branch">
                        <td>{{ branchs.id }}</td>
                        <td>{{ branchs.no}}</td>
                        <td>{{ branchs.code }}</td>
                        <td>{{ branchs.name}}</td>
                        <td>{{ branchs.branchStatus === '1' ? 'active':'non active'}}</td>
                        <td><a [routerLink]="['/manage/branch/update/', branchs.id]"
                            routerLinkActive="router-link-active" class="btn btn-sm btn-clean btn-icon mr-2"
                            title="Edit details">
                            <span class="svg-icon svg-icon-md"> <svg xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <rect x="0" y="0" width="24" height="24"></rect>
                                  <path
                                    d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                    fill="#000000" fill-rule="nonzero"
                                    transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) ">
                                  </path>
                                  <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                                </g>
                              </svg> </span> </a> | <a href="javascript:;" (click)="deletePost(branchs.id)"
                            class="btn btn-sm btn-clean btn-icon" title="Delete"> <span class="svg-icon svg-icon-md">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <rect x="0" y="0" width="24" height="24"></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000" fill-rule="nonzero"></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000" opacity="0.3"></path>
                                </g>
                              </svg> </span> </a></td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="branch?.length == 0">
                      <tr>
                        <td colspan="3" class="no-data-available">No data!</td>
                      </tr>
                    </tbody>
                  </table>
                  <!--end: Datatable-->
                </div>
              </div>
              <!--end::Card-->
            </div>
            <!--end::Container-->
          </div>
          <!--end::Entry-->
        </div>
        <!--end::Content-->
        <app-footer></app-footer>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
</div>
<!-- edit -->
<!--end::Main-->

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from 'src/app/helper/helper';
import { AssetsService } from 'src/app/service/assets/assets.service';
import { CategoryService } from 'src/app/service/category/category.service';
import { FileService } from 'src/app/service/file/file.service';

@Component({
  selector: 'app-update-interpace',
  templateUrl: './update-interpace.component.html',
  styleUrls: ['./update-interpace.component.css']
})
export class UpdateInterpaceComponent implements OnInit {
  showLoading = false;
  myOptions: any;
  data: any = {};
  id: number;
  selectedCategory:any;

  myForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    classification: new FormControl("", [Validators.required]),
    description: new FormControl(""),
    categoryId: new FormControl("", [Validators.required]),
    imageId: new FormControl(""),
  });
  
  constructor(
    private assetService: AssetsService,
    private categoryService: CategoryService,
    private fileService: FileService, 
    public helper: Helper,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  get f() {
    return this.myForm.controls;
  }
  ngOnInit(): void {
    this.getbyIdInterpace();
  }

  getbyIdInterpace() {
    this.id = this.route.snapshot.params["id"];
    this.assetService.getbyIdAsset(this.id).subscribe(
      (res) => {
        this.data = res;
        this.selectedCategory = res.category.id;
        this.categorychange(res.category.name);
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
  categorychange(e){
    this.categoryService
      .showCategory(`?page=${0}`, `&key=${e}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name,
            };
             arr.push(data);
          });
          this.myOptions=arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  uploadImage(e){
    this.fileService.postFiles(e.target.files[0]).subscribe(
      (res) => {
        this.data=res;
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
      }
    );
  }

  onSearchCategory(searchValue) {
    this.categoryService
      .showCategory(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name,
            };
             arr.push(data);
          });
          this.myOptions=arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }

  submit() {
    if (this.myForm.invalid) {
      return;
    }
    this.showLoading = true;
    this.id = this.route.snapshot.params["id"];
    this.assetService.putAsset(this.myForm.value, this.id).subscribe(
      (res) => {
        this.helper.alertStatus(200, "asset has been save");
        this.showLoading = false;
        this.router.navigateByUrl("/manage/asset/index");
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { City } from "src/app/models/city";
import { CityserviceService } from "src/app/service/city/cityservice.service";
import {
  NgbModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IOption } from "ng-select";
import { CountryService } from "src/app/service/country/country.service";
import { Helper } from "src/app/helper/helper";

@Component({
  selector: "app-city",
  templateUrl: "./city.component.html",
  styleUrls: ["./city.component.css"],
})
export class CityComponent implements OnInit {
  showLoading = false;
  dtOptions: DataTables.Settings = {};
  city: City[] = [];
  closeResult = "";
  showModal: boolean;
  id: number;
  detail: any = {};
  data: any = {};
  myOptions: any
  myOptionsEdit: any
  showEditContry: any

  myForm = new FormGroup({
    code: new FormControl("", [Validators.required]),
    countryId: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });
  myFormEdit = new FormGroup({
    id: new FormControl("", [Validators.required]),
    code: new FormControl("", [Validators.required]),
    countryId: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
    countryName:new FormControl("", [Validators.required]),
  });
  constructor(
    private cityService: CityserviceService,
    private countryService: CountryService,
    private modalService: NgbModal,
    public helper: Helper
  ) {}

  ngOnInit(): void {
    this.getCity();
    this.getChangeCountry('')
  }
  getCity() {
    this.showLoading = true;
    const that = this;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const pages =
          "" + dataTablesParameters.search.value === ""
            ? "?page=" + (dataTablesParameters.draw - 1) + ""
            : "?page=0" + "";
        const size = "&size=" + dataTablesParameters.length + "";
        const search =
          dataTablesParameters.search.value === ""
            ? ""
            : "&key=" + dataTablesParameters.search.value + "";
        this.cityService.getCity(pages, size, search).subscribe((res) => {
          that.city = res.content;
          this.showLoading = false;
          callback({
            recordsTotal: res.totalElements,
            recordsFiltered: res.totalPages,
            data: [],
          });
        });
      },
    };
  }
  onSearchCountry(searchValue) {
    this.countryService
      .getCountry(`?page=${0}`, `&key=${searchValue.term}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name + '-' + item.code,
            };
             arr.push(data);
          });
          this.myOptions=arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  get f() {
    return this.myForm.controls;
  }

  get e() {
    return this.myFormEdit.controls;
  }
  submit() {
    if (this.myForm.invalid) {
      return;
    }
    this.showLoading = true;
    this.cityService.postCity(this.myForm.value).subscribe(
      (res) => {
        this.helper.alertStatus(200, "city has been save");
        this.modalService.dismissAll();
        window.location.reload()
        this.showLoading = false;
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }

  deletePost(id) {
    this.showLoading = true;
    this.cityService.deleteCity(id).subscribe(
      (res) => {
        this.helper.alertStatus(200, "city has been save");
        this.getCity();
        this.showLoading = false;
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
  editModal(id) {
    this.showLoading = true;
    this.showModal = true;
    this.cityService.getbyIdCity(id).subscribe(
      (res) => {
          let body ={
            code:res.code,
            id:res.id,
            name:res.name,
            country:res.country.name,
            countryId:res.country.id,
          }
        
          this.detail = body;
          this.showLoading = false;
          this.showEditContry=res.country.id;
          this.getChangeCountry(res.country.name)
      },
      (err) => {
        this.showLoading = false;}
    );
  }
  submitEdit() {
    this.showLoading = true;
    let data = {
      code: this.myFormEdit.value.code,
      countryId: this.myFormEdit.value.countryId,
      name: this.myFormEdit.value.name
    }
    this.cityService.putCity(data, this.myFormEdit.value.id).subscribe(
      (res) => {
        this.helper.alertStatus(200, "city has been save");
        this.showModal = false;
        this.showLoading = false;
        window.location.reload()
      },
      (err) => {
        this.helper.alertStatus(
          typeof err.error.status === "undefined" ? 500 : err.error.status,
          typeof err.error.status === "undefined"
            ? "internal server error"
            : err.error.message
        );
        this.showLoading = false;
      }
    );
  }
  hide() {
    this.showModal = false;
  }

  getChangeCountry(e) {
    this.countryService
      .getCountry(`?page=${0}`, `&key=${e}`, `&size=${10}`)
      .subscribe(
        (res) => {
          const arr = [];
          res.content.map((item) => {
            let data = {
              id: item.id,
              name: item.name + "-" + item.code,
            };
            arr.push(data);
          });
          this.myOptions = arr;
        },
        (err) => {
          this.helper.alertStatus(
            typeof err.error.status === "undefined" ? 500 : err.error.status,
            typeof err.error.status === "undefined"
              ? "internal server error"
              : err.error.message
          );
        }
      );
  }
}

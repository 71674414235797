import { Injectable } from "@angular/core";
import { User } from "../models/user";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { endPoint } from "../classes/endpoints";
import { Helper } from "../helper/helper";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient, private helper: Helper) {}

  public signIn(userData: User) {
    const body = new HttpParams()
      .set("username", userData.username)
      .set("password", userData.password)
      .set("grant_type", "password");
    return this.http.post(`${endPoint.login}`, body, {
      headers: new HttpHeaders({
        Authorization: "Basic d2ViYXBwXzg0OTAyMzEyNDpHREZ5dTI3WGN2WGQ=",
        "Content-type": "application/x-www-form-urlencoded",
      }),
    });
  }
  setAuthorization(authorization) {
    const token = authorization.access_token;
    const exp = authorization.expires_in;
    localStorage.setItem("ACCESS_TOKEN", token);
  }
  public isLoggedIn() {
    console.log();
    if (localStorage.getItem("ACCESS_TOKEN") === null) {
      return false;
    } else {
      const token = this.helper.jwtDecode(localStorage.getItem("ACCESS_TOKEN"));
      if (token.exp < Date.now() / 1000) {
        return false;
      } else {
        return true;
      }
    }
  }
  public logout() {
    localStorage.removeItem("ACCESS_TOKEN");
  }
}
